import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  List,
  message,
  Switch,
  Tabs,
  Typography,
  Select,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import store from 'store'
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { getAPI, getAPIData } from 'services/jwt'
import EditNotesForm from './editNotesForm'
const { TabPane } = Tabs
const { Option } = Select
const doc_mapping = {
  invoice: 'Invoice',
  sales_return: 'Sales Return',
  purchase: 'Purchase',
  purchase_return: 'Purchase Return',
  purchase_order: 'Purchase Order',
  estimate: 'Quotation',
  delivery_challan: 'Delivery Challan',
  pro_forma_invoice: 'Pro Forma Invoice',
  packing_list: 'Packing List',
}
const CustomNotes = forwardRef((props, ref) => {
  const integrations = useSelector(state => state.permissions.integrations)
  const [key, setKey] = useState('1')
  const [showdocumentDrawer, setShowDocumentDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [noteList, setNoteList] = useState([])
  const [doc_type, setDocType] = useState('')
  const [doc_name, setDocName] = useState('')
  const [is_notes, setIsNotes] = useState(false)

  const formItemLayout = {
    // labelCol: {
    //   sm: { span: 8 },
    // },
    // wrapperCol: {
    //   sm: { span: 18 },
    // },
  }
  const editNotesRef = createRef()
  const deleteModalRef = createRef()
  const onTabChange = async key => {
    setIsNotes(key == '1')
    setKey(key)
  }
  const getDocumentNotes = async () => {
    const req = {
      is_notes,
      document_type: doc_type,
    }
    const data = await getAPI('user', 'get_document_notes', req)
    if (data.success) {
      setNoteList(data.data)
    }
  }
  const showDrawer = (document_type = 'invoice', is_notes = 1) => {
    setShowDocumentDrawer(true)

    let index = is_notes ? '1' : '2'

    setIsNotes(is_notes)

    setDocType(document_type)
    setDocName(doc_mapping[document_type])

    onTabChange(index.toString())
  }
  const editNotes = data => {
    editNotesRef?.current?.showDrawer(data)
  }
  const onChangeNote = async req => {
    const data = await getAPI('user', 'edit_document_notes', req)
    if (data.success) {
      await getDocumentNotes()
    }
  }

  useImperativeHandle(ref, () => ({
    showDrawer,
  }))
  useEffect(() => {
    console.log('props', props)
    setDocType(props.doc_type)
    setDocName(doc_mapping[props.doc_type])
    setIsNotes(props.is_notes)
    setKey(props.is_notes ? '1' : '2')
  }, [])
  useEffect(() => {
    if (doc_type != '') {
      getDocumentNotes()
    }
  }, [doc_type, is_notes])

  return (
    <div className="w-full">
      <Card className="w-full">
        <Tabs
          defaultActiveKey={'1'}
          activeKey={key}
          onChange={onTabChange}
          destroyInactiveTabPane={true}
          tabBarExtraContent={
            <>
              <Button
                type="primary"
                style={{ borderColor: 'blue' }}
                onClick={() =>
                  editNotesRef?.current?.showDrawer({
                    note_id: -1,
                    is_delete: 0,
                    is_active: 1,
                    is_notes,
                    document_type: doc_type,
                    notes: '',
                    is_default: 0,
                    label: '',
                  })
                }
                block
                icon={<PlusOutlined className="font-weight-bold" />}
              >
                <span className="font-weight-bold">
                  New {doc_name} {is_notes ? 'Notes' : 'Terms'}
                </span>
              </Button>
            </>
          }
        >
          <TabPane tab="Notes" key="1"></TabPane>
          <TabPane tab="Terms" key="2"></TabPane>
        </Tabs>
        {doc_type != '' && (
          <div className="w-full">
            <Select
              placeholder="Select document type"
              value={doc_type}
              onChange={e => {
                setDocType(e)
                setDocName(doc_mapping[e])
              }}
              className="w-1/4"
            >
              {Object.keys(doc_mapping)?.map(key => (
                <Option
                  key={key}
                  value={key}
                  disabled={
                    key == 'packing_list' &&
                    (is_notes == 0 ||
                      (store.get('is_export') == 0 && integrations?.packing_list == 0))
                  }
                >
                  {doc_mapping[key]}
                </Option>
              ))}
            </Select>
          </div>
        )}
        {Array.from({ length: 2 }, (_, index) => (index + 1).toString())
          .concat('4')
          .includes(key) ? (
          <>
            <List
              header={
                <div className="w-full flex justify-between">
                  <div className="w-3/4">
                    <Typography.Text
                      style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                      className="p-1"
                    >
                      Note: Active fields will be shown in the {doc_name}{' '}
                      {is_notes ? 'Notes' : 'Terms'}.
                    </Typography.Text>
                  </div>
                  {/* <div className='flex w-1/4 justify-between gap-2'>
                    <div className="w-1/3">Default</div>
                    <div className="w-1/3">Active</div>
                    <div className="w-1/3 ">Actions</div>
                  </div> */}
                </div>
              }
              footer={
                <div className="center mt-4">
                  <Button
                    type="dashed"
                    style={{ borderColor: 'blue' }}
                    onClick={
                      () =>
                        //   store.get('paid') == 0
                        //     ? this.subscription.showModal()
                        // :
                        editNotesRef?.current?.showDrawer({
                          note_id: -1,
                          is_delete: 0,
                          is_active: 1,
                          is_notes,
                          document_type: doc_type,
                          notes: '',
                          is_default: 0,
                          label: '',
                        })
                      // editNotes({
                      //   note_id: -1,
                      //   is_delete: 0,
                      //   is_active: 1,
                      //   is_notes,
                      //   document_type: doc_type,
                      //   notes: '',
                      //   is_default: 0,
                      //   label: '',
                      // })
                    }
                    block
                    icon={<PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />}
                  >
                    <span className="font-weight-bold" style={{ color: 'blue' }}>
                      New {doc_name} {is_notes ? 'Notes' : 'Terms'}
                    </span>
                  </Button>
                </div>
              }
              dataSource={noteList}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Switch
                      checkedChildren="Default"
                      unCheckedChildren="Not Default"
                      checked={item.is_default}
                      style={{ color: 'blue' }}
                      onChange={(checked, e) => onChangeNote({ ...item, is_default: checked })}
                    />,
                    <Switch
                      checked={item.is_active}
                      style={{ color: 'blue' }}
                      onChange={(checked, e) => onChangeNote({ ...item, is_active: checked })}
                    />,
                    <a
                      onClick={e => editNotes(item)}
                      className="card-link font-weight-bold text-action font-size-14"
                    >
                      <span>Edit</span>
                      <i className="fa fa-pencil-o" />
                    </a>,
                    <span
                      className="text-danger font-weight-bold"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation()
                        deleteModalRef?.current?.showDeleteModal(item, true)
                      }}
                    >
                      Delete
                    </span>,
                  ]}
                >
                  <div className="w-3/5">
                    <span style={{ fontWeight: 'bold' }}>{item.label}</span>
                    <br />
                    {item.notes}
                  </div>
                </List.Item>
              )}
            />
            <CustomDeleteModal ref={deleteModalRef} onFinish={onChangeNote} />
          </>
        ) : null}
      </Card>
      <EditNotesForm
        ref={editNotesRef}
        doc_name={doc_name}
        is_notes={is_notes}
        refreshNotesTerms={getDocumentNotes}
      />
    </div>
  )
})
export default CustomNotes

import { Button, DatePicker, Input, Popover, Table } from 'antd'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { downloadAPI, getAPI } from 'services/jwt'
import store from 'store'

const { RangePicker } = DatePicker
dayjs.extend(utc)

import { LoadingOutlined } from '@ant-design/icons'
import BetaBadge from 'components/badges/beta'
import InfoComponent from 'components/badges/InfoComponent'
import { connect } from 'react-redux'
import { ReportsView } from './reportTable/reportView'

const { Search } = Input
// check if mobile device
const isMobile = window.innerWidth <= 500
class DayBook extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      date: dayjs().format(this.dateFormat),
      data: [],
      columns: [],
      filters: {},
      sorter: {},
      loading: false,
      is_search: false,
      total_amounts: [],
    }
  }

  callData = async (req = {}) => {
    if (req.download != 0) {
      if (!store.get('paid')) {
        this.subscriptionPlanRef.current.openModal('reports')
      }
    }

    this.setState({ loading: true })

    var req = {
      page: req.page,
      num_records: req.num_records,
      date: req.dates[0],
      download: req.download ?? 0,
      party_search: req.search ?? '',
      filters: req.filters ?? {},
      sorter: req.sorter,
      sort_type: req.sort_type,
    }

    const data = await downloadAPI('v2/reports', 'day_book', req)
    if (data) {
      if (data.success) {
        this.setState({
          data: data.transactions,
          total: data.total_records,
          columns: data.columns,
          total_amounts: data.totals,
        })
      }
    }
    this.setState({ loading: false })

    return { day_book: { data: data?.transactions ?? [], totals: data?.total_records ?? 0 } }
  }

  async componentDidMount() {
    // await this.callData()
  }

  tableChangeHandler = async (pagination, filters, sorter) => {
    if ('doc_type' in filters) {
      if (filters.doc_type == null) {
        filters.doc_type = []
      }
      this.setState({ filters: filters })
    }

    if ('order' in sorter && sorter.order) {
      this.setState({ sorter: sorter }, () => {
        this.callData()
      })
    } else {
      this.setState({ sorter: {} }, () => {
        this.callData()
      })
    }
  }

  partySearch = value => {
    this.setState({ party_search: value }, () => {
      this.callData()
    })
  }

  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    let columns = this.state.columns.map(col => {
      if (col.dataIndex === 'party_name') {
        return {
          ...col,

          title: 'Party Name',
          // filterType: 'text',
          width: 300,
          cellRenderer: params => (
            <span>
              <p className="mt-0 mb-0">
                {params.data.party_company ? params.data.party_company : params.data.party_name}
              </p>
              <p className="mt-1 mb-0 font-size-11 text-gray-400 pl-2">
                {params.data.party_gst_number}
              </p>
            </span>
          ),
        }
      }

      if (col.dataIndex === 'doc_type') {
        return {
          ...col,
          filters: {
            invoice: 'Invoice',
            purchase: 'Purchase',
            sales_return: 'Sale Return',
            purchase_return: 'Purchase Return',
            estimate: 'Estimate',
            delivery_challan: 'Delivery Challan',
            pro_forma_invoice: 'Proforma Invoice',
            expense: 'Expense',
            indirect_income: 'Indirect Income',
            payment: 'Payment',
          },
          filterType: 'list',
          filterIcon: filtered => (
            <i className="fa-solid fa-lg fa-filter" style={{ color: filtered ? 'purple' : '' }} />
          ),
        }
      }
      if (col.dataIndex === 'total_amount') {
        return {
          ...col,
          sorter: (a, b) => a.total_amount - b.total_amount,
        }
      }

      return col
    })

    return (
      <>
        {this.props.newReport ? (
          ''
        ) : (
          <div className="mt-2 mb-2 font-size-22 font-weight-bold">
            Day Book <BetaBadge />
          </div>
        )}

        <ReportsView
          ref={this.reportsViewRef}
          report={{ ...this.props.report, newReport: this.props.newReport }}
          allColumns={{ day_book: columns }}
          data={{
            data: { day_book: this.state.data },
            total: { day_book: this.state.total },
          }}
          callData={req => this.callData(req)}
          hasSearch={true}
          hasDatePicker={true}
          tooltips={this.state.tooltips}
          type={'day_book'}
          footer={this.state.total_amounts}
          setColumns={columns =>
            this.setState({
              columns: columns,
            })
          }
          hideComponents={['rangepicker']}
        />

        <InfoComponent
          title={'Note'}
          className="mt-2"
          description={
            'Day Book is a record of all transactions in a day. It is a book of original entry where all transactions are recorded in the order of their occurrence in the given date. It is a chronological record of all transactions.'
          }
        />

        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(DayBook)

import { Avatar, message } from 'antd'
import { history } from 'index'
import React from 'react'
import { config } from 'services/axios'
import { getAPI } from 'services/jwt'
import { useSelector } from 'react-redux'
import { ProductOption } from './productOption'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import axios from 'axios'

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

export const autoCapitalize = data => {
  return data.charAt(0).toUpperCase() + data.slice(1)
}

export const capitalizeFirstLetters = str => {
  if (str == undefined || str == null) return ''
  if (isUpperCaseString(str)) return str
  return str.toLowerCase().replace(/^\w|\s\w/g, function(letter) {
    return letter.toUpperCase()
  })
}

const isUpperCaseString = string => /[A-Z]|[\u0080-\u024F]/.test(string)

const getLuminance = hexColor => {
  var color = hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  var uicolors = [r / 255, g / 255, b / 255]
  var c = uicolors.map(col => (col <= 0.03928 ? col / 12.92 : ((col + 0.055) / 1.055) ** 2.4))

  return 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
}

// export const getTextColor = (bgColor, lightColor = '#FFFFFF', darkColor = '#000000') => {
//   var L = getLuminance(bgColor)
//   var L1 = getLuminance(lightColor)
//   var L2 = getLuminance(darkColor)

//   return L > Math.sqrt((L1 + 0.05) * (L2 + 0.05)) - 0.05 ? darkColor : lightColor
// }

export const getTextColor = color => {
  var hex = '#'
  var r, g, b
  if (color.indexOf(hex) > -1) {
    r = parseInt(color.substr(1, 2), 16)
    g = parseInt(color.substr(3, 2), 16)
    b = parseInt(color.substr(5, 2), 16)
  } else {
    color = color.match(/\d+/g)
    r = color[0]
    g = color[1]
    b = color[2]
  }

  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export const getMimeType = ext => {
  let mimeTypes = {
    aac: 'audio/aac',
    abw: 'application/x-abiword',
    ai: 'application/postscript',
    arc: 'application/octet-stream',
    avi: 'video/x-msvideo',
    azw: 'application/vnd.amazon.ebook',
    bin: 'application/octet-stream',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    csh: 'application/x-csh',
    css: 'text/css',
    csv: 'text/csv',
    doc: 'application/msword',
    dll: 'application/octet-stream',
    eot: 'application/vnd.ms-fontobject',
    epub: 'application/epub+zip',
    gif: 'image/gif',
    htm: 'text/html',
    html: 'text/html',
    ico: 'image/x-icon',
    ics: 'text/calendar',
    jar: 'application/java-archive',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    js: 'application/javascript',
    json: 'application/json',
    mid: 'audio/midi',
    midi: 'audio/midi',
    mp2: 'audio/mpeg',
    mp3: 'audio/mpeg',
    mp4: 'video/mp4',
    mpa: 'video/mpeg',
    mpe: 'video/mpeg',
    mpeg: 'video/mpeg',
    mpkg: 'application/vnd.apple.installer+xml',
    odp: 'application/vnd.oasis.opendocument.presentation',
    ods: 'application/vnd.oasis.opendocument.spreadsheet',
    odt: 'application/vnd.oasis.opendocument.text',
    oga: 'audio/ogg',
    ogv: 'video/ogg',
    ogx: 'application/ogg',
    otf: 'font/otf',
    png: 'image/png',
    pdf: 'application/pdf',
    ppt: 'application/vnd.ms-powerpoint',
    rar: 'application/x-rar-compressed',
    rtf: 'application/rtf',
    sh: 'application/x-sh',
    svg: 'image/svg+xml',
    swf: 'application/x-shockwave-flash',
    tar: 'application/x-tar',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    ts: 'application/typescript',
    ttf: 'font/ttf',
    txt: 'text/plain',
    vsd: 'application/vnd.visio',
    wav: 'audio/x-wav',
    weba: 'audio/webm',
    webm: 'video/webm',
    webp: 'image/webp',
    woff: 'font/woff',
    woff2: 'font/woff2',
    xhtml: 'application/xhtml+xml',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.ms-excel',
    xlsx_OLD: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xml: 'application/xml',
    xul: 'application/vnd.mozilla.xul+xml',
    zip: 'application/zip',
  }
  return mimeTypes[ext]
}

export const formItemLayout = {
  layout: 'vertical',
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
}

export const formItemLayout_horizontal = {
  labelCol: {
    sm: { span: 8 },
    md: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
    md: { span: 16 },
  },
}

export const arrayBufferToBase64 = buffer => {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export const presets = {
  presets: [
    {
      label: 'Recommended',
      colors: [
        '#000000',
        '#000000E0',
        '#000000A6',
        '#00000073',
        '#00000040',
        '#00000026',
        '#0000001A',
        '#00000012',
        '#0000000A',
        '#00000005',
        '#F5222D',
        '#FA8C16',
        '#FADB14',
        '#8BBB11',
        '#52C41A',
        '#13A8A8',
        '#1677FF',
        '#2F54EB',
        '#722ED1',
        '#EB2F96',
        '#F5222D4D',
        '#FA8C164D',
        '#FADB144D',
        '#8BBB114D',
        '#52C41A4D',
        '#13A8A84D',
        '#1677FF4D',
        '#2F54EB4D',
        '#722ED14D',
        '#EB2F964D',
      ],
    },
  ],
}
const loadScript = src => {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

export const displayRazorpay = async (plan, num_years, addons) => {
  var data = await getAPI('user', 'subscription_details', {})
  let order_details = {}
  if (data.success) {
    order_details = data
  } else return
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?')
    return
  }

  // creating a new order
  var req = {
    plan_id: plan,
    value: num_years,
    addons,
  }
  var result = await getAPI('v2/doc', 'rzp_create_order', req)

  if (!result) {
    alert('Server error. Are you online?')
    return
  }

  if (!result.success) {
    message.error(result.message)
    return
  }

  const { amount, order_id, currency, public_token } = result

  const options = {
    key: public_token ?? config.url.RAZORPAY_ID,
    amount: amount.toString(),
    currency: currency,
    name: order_details.company.organization_name,
    description: order_details.company.company_name,
    image: order_details.company.logo == '' ? '' : order_details.company.logo,
    order_id: order_id,
    notes: {
      type: 'subscription',
      user_id: order_details.user.user_id,
      plan_id: plan,
      num_years: num_years,
      amount: amount,
      addons,
    },
    accept_partial: true,

    handler: async response => {
      var data = {
        orderCreationId: order_id,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
        addons,
      }

      var result = await getAPI('store', 'pay_success', data)
      try {
        if (result.success) {
          message.success(result.message)
          var req = {
            ...data,
            organization_name: order_details.company.organization_name,
            rzp_order_id: order_id,
            rzp_payment_id: data['razorpayPaymentId'],
            amount: amount,
            num_years: num_years,
            plan_id: plan,
          }
          message.info(
            'Thank you so much for subscribing. Please wait while we are updating your account...',
          )

          window.gtag('event', 'conversion', { send_to: 'AW-354450304/hK3MCPaslJ4ZEID3gakB' })

          var data = await getAPI('sales', 'subscription_pay_success', req)
          if (data) {
            message.success(data.message)
            window.location.reload()
            history.push('/list/sales')
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    prefill: {
      name: order_details.user.name,
      email: order_details.user.email,
      contact: order_details.user.phone,
    },
  }

  const paymentObject = new window.Razorpay(options)
  paymentObject.open()
}

export function breakName(name) {
  return [name]
}
export const PartyType = documentType => {
  let data = {
    invoice: 'customer',
    sales_return: 'customer',
    estimate: 'customer',
    delivery_challan: 'customer',
    pro_forma_invoice: 'customer',
    subscription: 'customer',
    purchase_order: 'vendor',
    purchase_return: 'vendor',
    purchase: 'vendor',
  }
  return data[documentType]
}

export const getNameInitials = name => {
  if (!name) {
    name = ''
  }

  // Extract initials from the name
  if (name == undefined || name == null) return ''

  const initials = name.includes(' ')
    ? name
        .split(' ')
        .map(word => word.charAt(0))
        .join('')
    : name.slice(0, 2)

  return initials.slice(0, 2).toUpperCase()
}

const getNumberFromInitials = initials => {
  const charCodes = initials.split('').map(char => char.charCodeAt(0))
  const sum = charCodes.reduce((acc, code) => acc + code, 0)
  return sum % 10 // Adjust the modulus based on your needs
}

// export const getRandomAvatarColor = (initials) => {
//   const charCodes = initials.split('').map(char => char.charCodeAt(0))
//   const sum = charCodes.reduce((acc, code) => acc + code, 0)
//   return sum % 10 // Adjust the modulus based on your needs
// }

export const getRandomUserAvatarColor = initials => {
  const number = getNumberFromInitials(initials)
  // var colors = [
  //   '#F7ECED',
  //   '#F4F3E8',
  //   '#EEF4E8',
  //   '#E7F4F1',
  //   '#EAE8F4',
  //   '#FFE8DA',
  //   '#e3f3ff',
  //   '#F3E4FF',
  //   '#FFE4F8',
  //   '#FFE4E4',
  // ]

  var colors = [
    '#BFE2FD', // Light Blue
    '#DDD5FF', // Light Purple
    '#FCC3D6', // Light Pink
    '#E8CBC7', // Light Peach
    '#FBA49D', // Light Coral
    '#FCBA94', // Light Orange
    '#F9DA90', // Light Yellow
    '#CCF6BE', // Light Green
    '#BFDED4', // Light Teal
  ]

  //for dark mode
  // '#dde67b',
  // '#bfded4',
  // '#f8b3b7',
  // '#a3e0ec',
  // '#93b7f8',
  // '#fcba94',
  // '#f9da90',
  // '#fa8fc4',
  // '#fba49d',
  // '#a092fd',

  // var colors = [
  //   '#dde67b',
  //   '#bfded4',
  //   '#f8b3b7',
  //   '#a3e0ec',
  //   '#93b7f8',
  //   '#fcba94',
  //   '#f9da90',
  //   '#fa8fc4',
  //   '#fba49d',
  //   '#a092fd',
  // ]
  return colors[number]
}

const darkenColor = (color, factor) => {
  // Function to manually darken a hex color
  const hexToRgb = hex => hex.match(/[A-Za-z0-9]{2}/g).map(v => parseInt(v, 16))

  const rgbToHex = (r, g, b) =>
    '#' + [r, g, b].map(x => Math.max(0, Math.min(255, x)).toString(16)).join('')

  const [r, g, b] = hexToRgb(color)
  const darkenedR = Math.round(r * (1 - factor))
  const darkenedG = Math.round(g * (1 - factor))
  const darkenedB = Math.round(b * (1 - factor))

  return rgbToHex(darkenedR, darkenedG, darkenedB)
}

export const UserAvatar = ({
  name = '',
  size = 'normal',
  shape = 'round',
  src = '',
  className = '',
}) => {
  if (!name) {
    name = ''
  }
  const initials = getNameInitials(name)
  const avatarStyle = {
    backgroundColor: src != '' ? '' : getRandomUserAvatarColor(initials),
    //border: '1px solid ' + (src == '' && darkenColor(getRandomUserAvatarColor(initials), 0.1)),
  }

  return (
    <>
      {name && (
        <div className={`${className} mr-2`}>
          <Avatar
            gap={size == 'large' ? 8 : 0}
            size={size}
            style={avatarStyle}
            src={src}
            shape={shape}
          >
            {src == '' && <span className="text-black-strict font-weight-bold">{initials}</span>}
          </Avatar>
        </div>
      )}
    </>
  )
}

export const CompanyAvatar = ({
  name = '',
  size = 'normal',
  shape = 'round',
  src = '',
  tick = false,
  className = '',
}) => {
  if (!name) {
    name = ''
  }
  const initials = getNameInitials(name)
  const avatarStyle = {
    backgroundColor: src == '' && getRandomUserAvatarColor(initials),
    //border: '1px solid ' + (src == '' && darkenColor(getRandomUserAvatarColor(initials), 0.1)),
  }

  return (
    <>
      {name != '' && (
        <>
          <div className={`${className} mr-2`}>
            <Avatar gap={0} size={size} style={avatarStyle} src={src} shape={shape}>
              {src == '' && (
                <span className={`text-black-strict font-weight-bold`}>{initials}</span>
              )}
            </Avatar>
            {tick == true && (
              <i className="fa fa-check-circle font-size-21 text-green avatar-tick" />
            )}
          </div>
        </>
      )}
    </>
  )
}

export const getBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

export const getStatusClass = data => {
  if (data < 0) {
    return '#e11900'
  } else if (data == 0) {
    return '#757575'
  } else {
    return '#09864A'
  }
}

export function get_auto_complete_product_format(data, document_settings) {
  return data.map((item, index) => ({
    ...item,
    key: item.product_id + '' + item.variant_id + '' + index,
    value: <ProductOption item={item} document_settings={document_settings} />,
  }))
}

export const getExpiryDays = (expiry_date, expiry_tolerance = 0) => {
  if (expiry_date == '' || expiry_date == '0000-00-00') {
    return ''
  }
  const startDate = dayjs()
  const endDate = dayjs(expiry_date, 'DD-MM-YYYY')

  const diff = endDate.diff(startDate)

  const durationObj = dayjs.duration(diff)
  let diff_days = durationObj.asDays()

  const is_expired = endDate.isBefore(dayjs()) || endDate.isSame(dayjs())

  const years = Math.floor(durationObj.asYears())
  const months = durationObj.subtract(dayjs.duration(years, 'years')).months()
  let days = durationObj
    .subtract(dayjs.duration(years, 'years'))
    .subtract(dayjs.duration(months, 'months'))
    .days()
  if (!is_expired && diff_days < 1) {
    days = 1
  }

  const years_str = `${years > 0 ? years + ' year' + (years !== 1 ? 's ' : ' ') : ''}`
  const months_str = `${months > 0 ? months + ' month' + (months !== 1 ? 's ' : ' ') : ''}`
  const days_str = `${days} day${days !== 1 ? 's' : ''}`

  return (
    <span>
      {is_expired ? (
        <span className="text-danger"> Expired</span>
      ) : (
        <span>
          Expires in {years_str}
          {months_str}
          {days_str}
        </span>
      )}
    </span>
  )
}

export const getPincodeDetails = async (pincode, states, countries) => {
  if (pincode) {
    let pincode_string = pincode.toString()

    if (pincode_string.length === 6) {
      try {
        const fetchDetails = await axios.get(
          `https://api.postalpincode.in/pincode/${pincode_string}`,
        )

        if (fetchDetails.data[0].PostOffice) {
          const post_office_data = fetchDetails.data[0].PostOffice[0]

          const region = post_office_data.Division
          let state_name = post_office_data.State.toUpperCase()
          const country_name = post_office_data.Country
          if (state_name == 'JAMMU & KASHMIR') {
            state_name = 'JAMMU AND KASHMIR'
          }
          const ans = states.filter(state => state.name.includes(state_name))
          const country = countries.filter(data => data.name == country_name)
          if (ans) {
            const state_value = ans[0].name
            const country_value = country[0].name
            const res = {
              state: state_value,
              city: region,
              country: country_value,
            }
            return [true, res]
          }

          return [false, {}]
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  return [false, {}]
}
export const getStatusClassNames = data => {
  switch (data) {
    case 'pending':
      return 'warning'
    case 'paid':
      return 'success'
    case 'cancelled':
      return 'danger'
    case 'partially paid':
      return 'partial'
    default:
      return 'danger'
  }
}
export const getMenuName = documentType => {
  switch (documentType) {
    case 'invoice':
      return 'Sales'
    case 'sales_return':
      return 'sales_returns'
    case 'estimate':
      return 'estimates'
    case 'delivery_challan':
      return 'delivery_challans'
    case 'pro_forma_invoice':
      return 'pro_forma_invoices'
    case 'purchase_order':
      return 'purchase_orders'
    case 'purchase_return':
      return 'purchase_returns'
    case 'purchase':
      return 'purchases'
    default:
      return 'Sales'
  }
}

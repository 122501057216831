import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  AutoComplete,
  Row,
  Select,
  message,
  InputNumber,
} from 'antd'
import BetaBadge from 'components/badges/beta'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { get_batch_settings, set_custom_columns, get_product_options } from 'redux/document/actions'
import SectionHeader from 'components/other/sectionHeader'
import ProductPriceList from 'components/PriceList'
import CustomColumnsForm from 'components/forms/customColumns'
import { getAPI, getAPIData } from 'services/jwt'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import store from 'store'
import AgGridTable from 'components/agGrid/agGridTable'
import AddPriceList from 'components/modal/addPriceList'
dayjs.extend(utc)

const { TextArea } = Input
const emptyData = {
  batch_no: '',
  mfg_date: '',
  expiry_date: '',
  purchase_price: 0,
  price: 0,
  opening_purchase_price: 0,
  opening_qty: 0,
}

class BatchingForm extends Component {
  formRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      defaultData: emptyData,
      product: {},
      is_price_with_tax: 1,
      is_purchase_price_with_tax: 1,
      selected_batch: {},
      is_edit: false,
      show_warning: false,
      batchSettings: this.props?.batch_settings,
      serial_number_label: this.props?.batch_settings.serial_number_label,
      fromDoc: false,
      serialNumberDefaultList: [''],
      oldDocSerialNumbers: [],
      gridSerialNumberList: [
        {
          id: 1,
          batch_no: '',
        },
      ],
      is_doc_edit: false,
      show_clear_warning: false,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    if (
      this.props.batch_settings == undefined ||
      Object.keys(this.props.batch_settings).length == 0
    ) {
      await this.props.dispatch(get_batch_settings())
      this.setState({
        serial_number_label: this.props?.batch_settings.serial_number_label,
      })
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  onAdd = (item, fromDoc = false, is_doc_edit = false) => {
    let serial_numbers = []
    let gridData = []
    if (is_doc_edit) {
      item.serial_numbers?.map((item, index) => {
        gridData.push({
          id: index + 1,
          batch_no: item.batch_no,
        })
        serial_numbers.push(item.batch_no)
      })
    }
    gridData.push({
      id: gridData.length + 1,
      batch_no: '',
    })

    this.setState({
      visible: true,
      is_serial: item.has_batches == 2,
      product: item,
      fromDoc: fromDoc,
      serialNumberDefaultList: is_doc_edit ? serial_numbers : [''],
      oldDocSerialNumbers: is_doc_edit ? item.serial_numbers : [],
      gridSerialNumberList: gridData,
      is_doc_edit: is_doc_edit,
    })
  }

  onEdit = (batch, item) => {
    this.setState(
      {
        visible: true,
        is_edit: true,
        product: item,
        is_serial: item.has_batches == 2,
        selected_batch: batch,
        is_price_with_tax: batch.is_price_with_tax,
        is_purchase_price_with_tax: batch.is_purchase_price_with_tax,
      },
      () => {
        let custom_fields = {}
        batch.custom_fields?.map(item => {
          if (item.value == '') {
            item.value = undefined
          }
          if (item.field_type == 'date' || item.field_type == 'datetime') {
            const dateFormatRegex = /^\d{2}-\d{2}-\d{4}/
            if (item.value && !dateFormatRegex.test(item.value)) {
              item.value = undefined
            } else {
              item.value = item.value ? dayjs(item.value, 'DD-MM-YYYY') : undefined
            }
          }
          custom_fields[item.name] = item.value
        })

        if (item.has_batches == 2) {
          //handle for serial numbers
          this.formRef.current.setFieldsValue({
            batch: {
              batch_no_0: batch.batch_no,
            },
            ...custom_fields,
          })
        } else {
          this.formRef.current.setFieldsValue({
            // ...batch,
            batch_no: batch.batch_no,

            mfg_date: batch.mfg_date == '' ? '' : dayjs(batch.mfg_date, this.dateFormat),
            expiry_date: batch.expiry_date == '' ? '' : dayjs(batch.expiry_date, this.dateFormat),
            qty: batch.qty,
            purchase_price: batch.is_purchase_price_with_tax
              ? batch.purchase_price
              : batch.purchase_unit_price,
            price: batch.is_price_with_tax ? batch.price_with_tax : batch.unit_price,
            opening_purchase_price: batch.opening_purchase_price,
            opening_qty: batch.opening_qty,
            opening_value: batch.opening_value,
            ...custom_fields,
          })
        }
      },
    )
  }

  showUnlinkedModal = async (req, unlinked_batches) => {
    Modal.confirm({
      title: 'Warning',

      content: (
        <>
          <p className="font-size-16 text-gray-600">
            {' '}
            The following entries are not linked to any document but are already in your inventory.{' '}
          </p>
          <div>
            {unlinked_batches.map(item => (
              <p>
                {item}
                <br />
              </p>
            ))}
          </div>
          <p className="text-danger font-size-16 mt-1">
            Do you want to link them to the current purchase?
          </p>
        </>
      ),
      cancelButtonProps: { type: 'danger' },
      width: '40%',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      onOk: () => {
        this.setState({ loading: false, is_edit: false, visible: false }, async () => {
          let batches = await req.all_batches.map(batch => {
            return {
              ...batch,
              link: true,
            }
          })
          this.props.onAPICalled(this.state.product, batches)
        })
        this.formRef.current.resetFields()
      },
      onCancel: () => {
        message.error('Please change/remove the records and try again')
      },
    })
    return
  }

  checkSerialNumberDuplicates = async req => {
    const check = await getAPI('product', 'check_duplicate_batches', req)
    if (check.success) {
      if (check.status) {
        if (check.unlinked_batches.length > 0 && this.state.is_serial) {
          this.showUnlinkedModal(req, check.unlinked_batches)
          return false
        }
        return true
      } else {
        message.error(check.message)
        return false
      }
    }
  }

  getBatchId = batch_no => {
    let batch_id = this.state.is_edit ? this.state.selected_batch.batch_id : -1
    if (this.state.is_doc_edit) {
      const index = this.state.oldDocSerialNumbers.findIndex(item => item.batch_no == batch_no)

      if (index != -1) {
        batch_id = this.state.oldDocSerialNumbers[index].batch_id
      }
    }
    return batch_id
  }
  onFinish = async values => {
    this.setState({ loading: true })

    let req = {}

    if (this.state.is_serial) {
      let batches = []

      if (this.state.is_edit) {
        values.batch = {
          0: {
            ...values,
            batch_no: values.batch.batch_no_0,
          },
        }

        delete values.batch[0].batch
      }

      Object.keys(values.batch).forEach(_batch => {
        let custom_fields = {}
        if (!this.state.fromDoc) {
          if (Object.keys(values.batch[_batch]).length > 1 && !this.state.fromDoc) {
            this.props.custom_columns_active?.map(item => {
              if (item.field_type == 'date' || item.field_type == 'datetime') {
                custom_fields[this.props.column_id_dict[item.name]] = values.batch[_batch][
                  item.name
                ]
                  ? values.batch[_batch][item.name].format(
                      item.field_type == 'date' ? this.dateFormat : 'DD-MM-YYYY HH:mm:ss',
                    )
                  : ''
              } else {
                values.batch[_batch][item.name] = values.batch[_batch][item.name]
                  ? values.batch[_batch][item.name]
                  : ''
                custom_fields[this.props.column_id_dict[item.name]] =
                  values.batch[_batch][item.name]
              }
            })
          }
        }

        batches.push({
          batch_id: this.getBatchId(values.batch[_batch].batch_no),
          batch_no: values.batch[_batch].batch_no,
          mfg_date: '',
          expiry_date: '',
          opening_qty: 1,
          opening_purchase_price: 0,
          opening_value: 0,
          purchase_price: this.state.product.purchase_price ? this.state.product.purchase_price : 0,
          price:
            this.state.product.is_price_with_tax && !this.state.fromDoc
              ? this.state.product.price_with_tax
              : this.state.product.price,
          price_with_tax: this.state.product.price_with_tax,
          purchase_unit_price: this.state.product.purchase_unit_price,
          is_price_with_tax: this.state.product.is_price_with_tax,
          is_purchase_price_with_tax: true,
          custom_fields: this.state.fromDoc ? [] : custom_fields,
        })
      })
      req = {
        product_id: this.state.product.product_id,
        variant_id: this.state.product.variant_id,
        batches: batches,
        all_batches: batches,
      }
      if (this.state.fromDoc) {
        if (this.state.is_doc_edit && this.state.serialNumberDefaultList.length > 0) {
          req['batches'] = batches.filter(
            item => !this.state.serialNumberDefaultList.includes(item.batch_no),
          )
        }
        if (req['batches']?.length > 0) {
          let check = await this.checkSerialNumberDuplicates(req)
          if (!check) {
            this.setState({ loading: false })
          } else {
            this.setState({ loading: false, is_edit: false, visible: false }, () =>
              this.props.onAPICalled(this.state.product, batches),
            )
            this.formRef.current.resetFields()
          }
        } else {
          this.setState({ loading: false, is_edit: false, visible: false }, () =>
            this.props.onAPICalled(this.state.product, batches),
          )
          this.formRef.current.resetFields()
        }
        return
      }
    } else {
      let custom_fields = {}
      this.props.custom_columns_active?.map(item => {
        if (item.field_type == 'date' || item.field_type == 'datetime') {
          custom_fields[this.props.column_id_dict[item.name]] = values[item.name]
            ? values[item.name].format(
                item.field_type == 'date' ? this.dateFormat : 'DD-MM-YYYY HH:mm:ss',
              )
            : ''
        } else {
          values[item.name] = values[item.name] ? values[item.name] : ''
          custom_fields[this.props.column_id_dict[item.name]] = values[item.name]
        }
      })

      values.custom_fields = custom_fields

      req = {
        product_id: this.state.product.product_id,
        variant_id: this.state.product.variant_id,
        batches: [
          {
            ...values,
            batch_id: this.state.is_edit ? this.state.selected_batch.batch_id : -1,
            batch_no: values.batch_no,
            mfg_date: values.mfg_date == '' ? '' : dayjs(values.mfg_date).format(this.dateFormat),
            expiry_date:
              values.expiry_date == '' ? '' : dayjs(values.expiry_date).format(this.dateFormat),
            qty: values.qty,
            purchase_price: values.purchase_price === '' ? 0 : values.purchase_price,
            price: values.price === '' ? 0 : values.price,
            is_price_with_tax: this.state.is_price_with_tax,
            is_purchase_price_with_tax: this.state.is_purchase_price_with_tax,
          },
        ],
      }
    }
    const url = this.state.is_edit ? 'edit_batch' : 'add_batch'
    const data = await getAPI('product', url, req)
    if (data && data.success) {
      message.success(data.message)
      this.setState({ loading: false, is_edit: false, visible: false }, () =>
        this.props.onAPICalled(),
      )
      this.formRef.current.resetFields()
    }
    this.setState({ loading: false })
  }

  handleSubmit = () => {
    if (
      this.formRef.current.getFieldValue('opening_qty') == 0 &&
      !this.state.is_serial &&
      !this.state.is_edit &&
      this.props.warehouses?.length == 0
    ) {
      this.setState({
        show_warning: true,
      })
    } else {
      if (this.state.is_serial && !this.state.is_edit) {
        if (this.gridRef) {
          var batches = this.gridRef.getRowData() ?? []
          batches = batches.filter(item => item.batch_no != '')
          this.setState({ serialNumberDefaultList: batches.map(item => item.batch_no) }, () =>
            this.onFinish({
              batch: batches,
            }),
          )
        }
      } else {
        this.formRef.current.submit()
      }
    }
  }

  handleAddField = () => {
    const batch = this.formRef.current.getFieldValue('batch') || []
    const newBatch = [...batch, { batch_no: '' }]
    this.formRef.current.setFieldsValue({ batch: newBatch })
  }

  handleRemoveField = index => {
    const batch = this.formRef.current.getFieldValue('batch') || []
    const newBatch = [...batch]
    newBatch.splice(index, 1)
    this.formRef.current.setFieldsValue({ batch: newBatch })
  }

  handleBarcodeScan = (index, e) => {
    const batch = this.formRef.current.getFieldValue('batch') || []
    const newBatch = [...batch]
    newBatch[index] = e.target.value
    this.formRef.current.setFieldsValue({ batch: newBatch })
  }

  disabledExpiryDate = current => {
    try {
      const manufactureDate = dayjs(this.formRef.current.getFieldValue('mfg_date'))
      return current && current <= manufactureDate.startOf('day')
    } catch (error) {
      return false
    }
  }
  renderCustomColumnOptions = header_id => {
    let options = []
    this.props.custom_column_suggestions?.[header_id]?.map((item, index) => {
      options.push({
        value: item,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {item}
          </div>
        ),
      })
    })
    return options
  }

  getSuggestedColumns = async () => {
    const data = await getAPIData('v2/custom_fields', 'item')
    if (data.success) {
      this.props.dispatch(
        set_custom_columns(
          data.custom_columns.map(item => {
            return {
              ...item,
              is_active: item.default_key != '' ? false : item.is_active,
              show_in_pdf: item.default_key != '' ? false : item.show_in_pdf,
            }
          }),
        ),
      )
    }
  }

  checkExistingSerialNo = (batch_no, id, params) => {
    if (
      this.state.gridSerialNumberList.filter(
        item => item.batch_no != '' && item.id != id && item.batch_no == batch_no,
      ).length > 0
    ) {
      message.error('Duplicate ' + this.state.serial_number_label + 'are not allowed')
      params.node.updateData({
        ...params.data,
        batch_no: params.oldValue,
        message: this.state.serial_number_label + ' already exists',
        error: true,
      })
      this.setState({
        gridSerialNumberList: this.gridRef.getRowData(),
        serialNumberDefaultList: this.gridRef.getRowData().map(item => item.batch_no),
      })
      this.selectCellForEditing(params.node.rowIndex)
      return true
    }

    return false
  }

  selectCellForEditing = rowIndex => {
    this.gridRef.api().stopEditing()
    setTimeout(
      () =>
        this.gridRef.api().startEditingCell({
          rowIndex: rowIndex,
          colKey: 'batch_no',
        }),
      50,
    )
  }

  handleGridCellUpdate = params => {
    if (this.checkExistingSerialNo(params.data.batch_no, params.data.id, params)) return

    if (
      this.state.gridSerialNumberList[this.state.gridSerialNumberList.length - 1].id ===
      params.data.id
    ) {
      if (params.data.batch_no != '') {
        this.setState(
          {
            gridSerialNumberList: [
              ...this.state.gridSerialNumberList,
              {
                id: this.state.gridSerialNumberList.length + 1,
                batch_no: '',
              },
            ],
            serialNumberDefaultList: this.state.gridSerialNumberList.map(item => item.batch_no),
          },
          () => {
            // params.api.setGridOption('rowData', this.state.gridSerialNumberList)
            params.node.updateData({ ...params.data, message: '', error: false })
            this.selectCellForEditing(this.state.gridSerialNumberList.length - 1)
          },
        )
      }
    } else {
      if (params.data.batch_no == '') {
        message.error(this.state.serial_number_label + ' cannot be emtpy ')
        params.node.updateData({
          ...params.data,
          batch_no: params.oldValue,
          message: this.state.serial_number_label + ' cannot be emtpy !',
          error: true,
        })
        this.selectCellForEditing(params.node.rowIndex)

        return
      }

      params.node.updateData({ ...params.data, message: '', error: false })
      this.selectCellForEditing(this.state.gridSerialNumberList.length - 1)

      this.setState({
        gridSerialNumberList: this.gridRef.getRowData(),
        serialNumberDefaultList: this.gridRef.getRowData().map(item => item.batch_no),
      })
    }
  }

  processDataFromClipboard = params => {
    var gridData = this.state.gridSerialNumberList
    gridData = gridData.splice(0, this.state.gridSerialNumberList.length - 1)
    const gridLength = gridData.length + 1

    var newData = [...new Set(params.data.flat())]
      .filter(item => item != '' && !this.state.serialNumberDefaultList.includes(item))
      .map((item, index) => ({ id: index + gridLength, batch_no: item }))

    gridData = [...gridData, ...newData, { id: gridLength + newData.length, batch_no: '' }]

    this.setState({
      gridSerialNumberList: gridData,
      serialNumberDefaultList: gridData.map(item => item.batch_no),
    })
  }

  refreshPriceList = async () => {
    await this.props.dispatch(get_product_options())
  }

  render() {
    const formItemLayout = {
      layout: 'vertical',
      labelCol: {
        sm: { span: 24 },
      },
      wrapperCol: {
        sm: { span: 24 },
      },
    }

    var serialNoColumns = [
      {
        title: 'ID',
        dataIndex: 'id',
        maxWidth: 100,
        editable: false,
        suppressMovable: true,
        sortable: false,
      },
      {
        title: this.state.serial_number_label ?? 'Serial Number',
        dataIndex: 'batch_no',
        suppressMovable: true,
        // width: 20,
        sortable: false,
      },
    ]

    if (!this.state.is_edit && !this.state.fromDoc) {
      var customColumns = this.props.custom_columns_active?.map((item, c_index) => {
        if (item.applicable_for == 'batch') {
          return {
            title: item.name,
            dataIndex: item.name,
            suppressMovable: true,
            sortable: false,
            editable: true,
            cellEditor: item.field_type,
            cellEditorPopup: true,
            cellEditorPopupPosition: 'under',
            cellEditorParams: {
              rows: 10,
              cols: 20,
            },
          }
        }
      })

      customColumns = customColumns.filter(item => item != undefined)

      serialNoColumns = [...serialNoColumns, ...customColumns]
    }

    serialNoColumns = [
      ...serialNoColumns,
      {
        title: 'Action',
        dataIndex: 'action',
        maxWidth: 100,
        editable: false,
        suppressMovable: true,
        sortable: false,
        cellRenderer: params => {
          return (
            params.data.id != this.state.gridSerialNumberList.length && (
              <Button
                type="delete"
                onClick={() => {
                  this.setState({
                    gridSerialNumberList: this.state.gridSerialNumberList
                      .filter(item => item.id != params.data.id)
                      .map((item, i) => {
                        return { ...item, id: i + 1 }
                      }),
                  })
                }}
                className="text-danger"
                disabled={params.data.id == this.state.gridSerialNumberList.length}
              >
                Remove
              </Button>
            )
          )
        },
      },
    ]

    return (
      <div>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  {this.state.is_serial ? (
                    <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                      {this.state.is_edit
                        ? 'Edit ' + this.state.serial_number_label
                        : 'Add New ' +
                          this.state.serial_number_label +
                          ' for ' +
                          this.state.product.product_name}
                    </span>
                  ) : (
                    <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                      {this.state.is_edit
                        ? 'Edit Batch'
                        : 'Add New Batch for ' + this.state.product.product_name}
                    </span>
                  )}
                  <BetaBadge />
                </div>
              </div>
            </>
          }
          placement={isMobile ? 'top' : 'right'}
          width={
            this.state.is_serial
              ? this.props.custom_columns_active.length > 2
                ? '65%'
                : '45%'
              : '50%'
          }
          // width={this.state.is_serial ? '40%' : '50%'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onClose={() =>
            this.setState({
              visible: false,
              is_edit: false,
              selected_batch: {},
              show_warning: false,
            })
          }
          open={this.state.visible}
          maskClosable={false}
          destroyOnClose={true}
          footer={
            <>
              <Button
                onClick={() => this.handleSubmit()}
                type="primary"
                className="mr-2"
                loading={this.state.loading}
              >
                Save
              </Button>

              <Button
                onClick={() =>
                  this.setState(
                    {
                      visible: false,
                      is_edit: false,
                      selected_batch: {},
                      show_warning: false,
                    },
                    () => this.formRef.current.resetFields(),
                  )
                }
                type="danger"
              >
                Close
              </Button>
            </>
          }
        >
          {/* Add Batch */}
          <div>
            <Form
              {...formItemLayout}
              labelAlign="left"
              ref={this.formRef}
              name="batchForm"
              onFinish={this.onFinish}
              onFinishFailed={() => message.error('Check All Details Again')}
              initialValues={{ ...this.state.defaultData }}
            >
              <Card>
                {this.state.is_serial ? (
                  <>
                    {this.state.is_edit ? (
                      <>
                        <Input.Group size="large">
                          {[...Array(1)].map((item, i) => (
                            <Form.Item
                              label={this.state.serial_number_label}
                              name={['batch', [['batch_no_' + i]]]}
                              key={i}
                              defaultValue={''}
                              rules={[
                                {
                                  required: this.state.is_serial,
                                  message: 'Missing ' + this.state.serial_number_label,
                                },
                              ]}
                            >
                              <Input placeholder={this.state.serial_number_label} />
                            </Form.Item>
                          ))}
                        </Input.Group>
                      </>
                    ) : (
                      <Row gutter={16}>
                        <Col span={24}>
                          <Card className="bg-gray-50" bordered={false}>
                            <div className="flex justify-between">
                              <h6 className="text-gray-500">
                                Enter {this.state.serial_number_label}
                              </h6>

                              <a
                                className="text-danger font-size-15"
                                onClick={() => this.setState({ show_clear_warning: true })}
                              >
                                Clear all
                              </a>

                              <Modal
                                title="Confirmation"
                                open={this.state.show_clear_warning}
                                onOk={() => {
                                  this.setState({ show_clear_warning: false })
                                  this.formRef.current.resetFields()
                                  this.setState({
                                    gridSerialNumberList: [{ id: 1, batch_no: '' }],
                                    serialNumberDefaultList: [],
                                  })
                                }}
                                onCancel={() => this.setState({ show_clear_warning: false })}
                              >
                                <span className="text-danger">
                                  Are you sure you want to clear all the fields?
                                </span>
                              </Modal>
                            </div>

                            <AgGridTable
                              gridKey={'SerialNumbers'}
                              onReady={async event => {
                                this.selectCellForEditing(
                                  this.state.gridSerialNumberList.length - 1,
                                )
                              }}
                              dataSource={this.state.gridSerialNumberList}
                              columns={serialNoColumns}
                              lockVisible={true}
                              // singleClickEdit={true}
                              onRef={ref => (this.gridRef = ref)}
                              rowId={'id'}
                              // readOnlyEdit={true}
                              editable={true}
                              tooltipText={'message'}
                              onUpdateValue={() => ''}
                              enableAdvancedFilter={true}
                              pagination={false}
                              processDataFromClipboard={this.processDataFromClipboard}
                              onCellEditingStopped={params => {
                                this.handleGridCellUpdate(params)
                              }}
                              stopEditingWhenCellsLoseFocus={true}
                            />
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </>
                ) : (
                  <Row gutter={18}>
                    <Col span={12}>
                      <Form.Item
                        label="Batch No."
                        name="batch_no"
                        rules={[{ required: true, message: 'Missing Column Name' }]}
                      >
                        <Input disabled={this.state.edit ? true : false} placeholder="Batch No" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Manufacture Date" name="mfg_date">
                        <DatePicker
                          autoFocus
                          allowClear={true}
                          format={this.dateFormat}
                          className="mb-2 w-full"
                          placeholder="Manufacture Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Expiry Date" name="expiry_date">
                        <DatePicker
                          autoFocus
                          allowClear={true}
                          format={this.dateFormat}
                          className="mb-2 w-full"
                          placeholder="Expiry Date"
                          disabledDate={this.disabledExpiryDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="price"
                        label={
                          <>
                            <span className="mr-2">Selling Price</span>
                          </>
                        }
                      >
                        <Input
                          type="number"
                          placeholder="Enter Selling Price"
                          addonAfter={
                            <Select
                              style={{ width: 120 }}
                              onChange={e => {
                                this.setState({ is_price_with_tax: e })
                              }}
                              value={this.state.is_price_with_tax}
                            >
                              <Option value={1}>with Tax</Option>

                              <Option value={0}>without Tax</Option>
                            </Select>
                          }
                          prefix={
                            <span className="text-gray-500">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                          }
                          size="large"
                          className="font-weight-bold"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="purchase_price"
                        label={
                          <>
                            <span className="mr-2">Purchase Price</span>
                          </>
                        }
                      >
                        <Input
                          type="number"
                          placeholder="Enter Purchase Price"
                          addonAfter={
                            <Select
                              style={{ width: 120 }}
                              onChange={e => {
                                this.setState({ is_purchase_price_with_tax: e })
                              }}
                              value={this.state.is_purchase_price_with_tax}
                            >
                              <Option value={1}>with Tax</Option>

                              <Option value={0}>without Tax</Option>
                            </Select>
                          }
                          prefix={
                            <span className="text-gray-500">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                          }
                          size="large"
                          className="font-weight-bold"
                        />
                      </Form.Item>
                    </Col>
                    {this.props.warehouses?.length == 0 && <Divider />}
                    <Col span={12}>
                      <Form.Item
                        name="opening_qty"
                        label="Opening Quantity"
                        extra="*Quantity of the product available in your existing inventory"
                        hidden={this.props.warehouses?.length > 0}
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (value < 0 && !this.state.formFlag) {
                                return Promise.reject('Quantity cannot be negative')
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                        onChange={e => {
                          var opening_purchase_price = this.formRef.current.getFieldValue(
                            'opening_purchase_price',
                          )
                            ? parseFloat(
                                this.formRef.current.getFieldValue('opening_purchase_price'),
                              )
                            : 0

                          var opening_qty = this.formRef.current.getFieldValue('opening_qty')
                            ? parseFloat(this.formRef.current.getFieldValue('opening_qty'))
                            : 0
                          this.formRef.current.setFieldsValue({
                            opening_value: opening_purchase_price * opening_qty,
                          })
                        }}
                      >
                        <Input placeholder="eg. 10" type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="opening_value"
                        label="Opening Stock Value (with tax)"
                        hidden={this.props.warehouses?.length > 0}
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (value < 0) {
                                return Promise.reject('Opening Value cannot be negative')
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                        onChange={e => {
                          var opening_purchase_price = this.formRef.current.getFieldValue(
                            'opening_value',
                          )
                            ? parseFloat(this.formRef.current.getFieldValue('opening_value'))
                            : 0

                          var opening_qty = this.formRef.current.getFieldValue('opening_qty')
                            ? parseFloat(this.formRef.current.getFieldValue('opening_qty'))
                            : 0
                          this.formRef.current.setFieldsValue({
                            opening_purchase_price: opening_purchase_price / opening_qty,
                          })
                        }}
                      >
                        <Input placeholder="eg. 100.00" type="number" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="opening_purchase_price"
                        label="Opening Purchase Price (with tax)"
                        hidden
                        rules={[
                          {
                            validator: (rule, value) => {
                              if (value < 0) {
                                return Promise.reject('Purchase Price cannot be negative')
                              }
                              return Promise.resolve()
                            },
                          },
                        ]}
                        onChange={e => {
                          var opening_purchase_price = this.formRef.current.getFieldValue(
                            'opening_purchase_price',
                          )
                            ? parseFloat(
                                this.formRef.current.getFieldValue('opening_purchase_price'),
                              )
                            : 0

                          var opening_qty = this.formRef.current.getFieldValue('opening_qty')
                            ? parseFloat(this.formRef.current.getFieldValue('opening_qty'))
                            : 0
                          this.formRef.current.setFieldsValue({
                            opening_value: opening_purchase_price * opening_qty,
                          })
                        }}
                      >
                        <Input placeholder="eg. 100.00" type="number" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Card>
              {(!this.state.is_serial || (this.state.is_serial && this.state.is_edit)) &&
                this.props.integrations?.variant_custom_columns == 1 && (
                  <Card className="mt-3">
                    {this.props.custom_columns_active.length > 0 && !this.state.fromDoc && (
                      <div>
                        <SectionHeader title="Custom Fields" optional={true} />

                        <Row gutter={18}>
                          {this.props.custom_columns_active?.map(
                            (item, index) =>
                              item.applicable_for == 'batch' && (
                                <Col span={12}>
                                  <Form.Item name={item.name} key={index} label={item.name}>
                                    {item.field_type == 'number' ||
                                    item.field_type == 'currency' ? (
                                      <InputNumber
                                        style={{ width: '100%' }}
                                        placeholder={item.name}
                                      />
                                    ) : item.field_type == 'date' ? (
                                      <DatePicker
                                        style={{ width: '100%' }}
                                        placeholder={item.name}
                                        format={this.dateFormat}
                                      />
                                    ) : item.field_type == 'datetime' ? (
                                      <DatePicker
                                        showTime
                                        style={{ width: '100%' }}
                                        placeholder={item.name}
                                        format={this.dateTimeFormat}
                                      />
                                    ) : item.field_type == 'bigtext' ? (
                                      <TextArea
                                        placeholder={item.name}
                                        type="text"
                                        rows={1}
                                        className="pr-4 mr-2"
                                        style={{ width: '100%', borderRadius: '5px' }}
                                      />
                                    ) : (
                                      <AutoComplete
                                        popupClassName="certain-category-search-dropdown"
                                        popupMatchSelectWidth={250}
                                        style={{
                                          width: '100%',
                                        }}
                                        placeholder={item.name}
                                        options={
                                          this.renderCustomColumnOptions(item.id).length > 0
                                            ? [
                                                {
                                                  label: <span>Recently Used </span>,
                                                  options: [
                                                    ...this.renderCustomColumnOptions(item.id),
                                                  ],
                                                },
                                              ]
                                            : []
                                        }
                                      ></AutoComplete>
                                    )}
                                  </Form.Item>
                                </Col>
                              ),
                          )}
                        </Row>
                        <Button
                          type="secondary"
                          className=""
                          onClick={() => {
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.custom_column.onAddFromProducts()
                          }}
                        >
                          <i className="fa-sharp fa-solid fa-circle-plus mr-2" />
                          <span className="font-weight-bold">Add Custom Fields</span>
                        </Button>
                      </div>
                    )}
                  </Card>
                )}
            </Form>
          </div>
          {this.state.selected_batch && this.state.is_edit && (
            <>
              <div className="mt-6 flex justify-between items-center">
                <div className="font-weight-bold">Price lists</div>
                <AddPriceList slimButton onPriceListAdded={this.refreshPriceList} />
              </div>
              <ProductPriceList
                product_id={this.state.selected_batch.product_id}
                variant_id={this.state.selected_batch.new_variant_id}
                batch_id={this.state.selected_batch.batch_id}
              />
            </>
          )}
          <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
          <CustomColumnsForm
            onRef={ref => (this.custom_column = ref)}
            onAPICalled={this.getSuggestedColumns}
          />

          <Modal
            title="Warning"
            open={this.state.show_warning}
            footer={
              <>
                <Button
                  type="danger"
                  onClick={() =>
                    this.setState({
                      show_warning: false,
                    })
                  }
                >
                  No
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    this.setState(
                      {
                        show_warning: false,
                      },
                      () => this.formRef.current.submit(),
                    )
                  }
                >
                  Yes
                </Button>
              </>
            }
          >
            <span className="text-danger">
              Opening Quantity is 0 for the current batch. Do you want to proceed?
            </span>
          </Modal>
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    batch_settings: state.document.batch_settings,
    custom_column_suggestions: state.document.productCustomColumnsSuggestion,
    custom_columns: state.document.custom_column_data.custom_columns_not_default,
    custom_columns_active: state.document.custom_column_data.custom_columns_not_default_active,
    column_id_dict: state.document.custom_column_data.column_id_dict,
    warehouses: state.warehouse.warehouses,
    user: state.user,
    integrations: state.permissions.integrations,
  }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(BatchingForm)

import { Button, Dropdown, Input, Menu, Tooltip } from 'antd'
import CustomerForm from 'components/forms/customerForm'
import ProductForm from 'components/forms/productForm'
import { VendorForm } from 'components/forms/vendorform'
import CustomPricing from 'components/modal/pricing'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import ChangeCompany from 'components/other/ChangeCompany'
import { displayRazorpay } from 'components/other/utility'
import dayjs from 'dayjs'
import { history } from 'index'
import { GetBeaconValue, GetLogo } from 'pages/firebasejs'
import IntercomSupportProvider from 'pages/support/interCom'
import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { config } from 'services/axios'
import { currentAccount, getAPI, getAPIData } from 'services/jwt'
import store from 'store'
import { v4 as uuidv4 } from 'uuid'
import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

import { Beacon } from '@beacon.li/bar'
import InventoryForm from 'components/forms/inventoryForm'
import PayInForm from 'components/forms/payForm'
import ExpenseForm from 'components/forms/expensesForm'
import { setBeacon } from 'redux/permissions/actions'
import Notifications from './Notifications'
import { update_notification_count, set_notification_count } from 'redux/document/actions'
import AIFileAssistant from 'components/modal/aiFileAssistant'
// import { Input } from 'antd'

const mapStateToProps = state => {
  const { menu, settings, user, permissions, welcome } = state
  return {
    menuData: menu.menuData,
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    theme: settings.theme,
    role: user.role,
    companies: user.companies,
    user: user,
    states: state.document.states,
    tds_sections: state.document.tdsSections,
    countries: state.document.countries,
    companyDetailsAdded: welcome.company_details_added,
    bankDetailsAdded: welcome.bank_details_added,
    productsAdded: welcome.products_added,
    invoices_created: welcome.invoices_created,
    permissions: permissions.permission,
    notification_count: state.document.notification_count,
  }
}

const TopBar = ({ dispatch, user, theme, logo, ...props }) => {
  const [tour, setTour] = useState(false)
  const permissions = useSelector(state => state.permissions.permission)
  const is_beacon_loaded = useSelector(state => state.permissions.is_beacon_loaded)
  const [selectedCompany, setCompany] = useState('')
  const [stateCompanies, setStateCompanies] = useState(user)
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredCompanies, setFilteredCompanies] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [totalCompanies, setTotalCompanies] = useState(0)
  const [tipVisible, setTipVisible] = useState(false)
  const customPricingRef = useRef(null)
  const subscriptionRef = useRef(null)
  const [is_renewal, setIsRenewal] = useState(false)
  const [renewal_dates, setRenewalDates] = useState({})
  const [downloadList, setdownloadList] = useState([])
  const [show_beacon_search, setShowBeaconSearch] = useState(false)
  const [isCreateDropdownVisible, setIsDropdownVisible] = useState(false)
  const [aiSearchText, setAiSearchText] = useState('')
  const [isAIModalVisible, setIsAIModalVisible] = useState(false)

  var show_one_day_timer = false
  var formatted_end_time = ''
  if (document.cookie.indexOf('one_time_offer') >= 0) {
    /*
    // cookie exists
    */
    var cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)one_time_offer\s*\=\s*([^;]*).*$)|^.*$/,
      '$1',
    )
    if (cookieValue) {
      var cookie_data = JSON.parse(cookieValue)
      var currentTime = new Date()
      var endTime = new Date(cookie_data.end_time)

      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }

      formatted_end_time = endTime.toLocaleString('en-US', options)

      if (currentTime < endTime) {
        show_one_day_timer = true
      }
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     Beacon.load(config.url.BEACON_ID)
  //     Beacon.loadUser(store.get('user_id'), {
  //       paid: store.get('paid'),
  //     })
  //   }, 2000)
  // }, [])

  const { boot, shutdown, hide, show, showNewMessage, update } = useIntercom()

  useEffect(() => {
    const BeaconSupportCallback = () => {
      if (props.permissions.intercom == 1) {
        showNewMessage()
      } else {
        window.open(
          'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I am looking for ',
          '_blank',
        )
      }
    }

    Beacon.addCallback('beacon_support_callback', BeaconSupportCallback)

    setStateCompanies(user)
    let current_company = user.companies.filter(item => item.current_company == 1)[0]
      ?.organization_name
    setCompany(current_company)

    if ((props.companyDetailsAdded || props.bankDetailsAdded) && props.invoices_created) {
      store.set('app.settings.tutorialTouched', true)
    }

    const FetchRemoteDetails = async () => {
      const logo = await GetLogo()
      if (logo != null && logo != undefined && logo != '') {
        dispatch({
          type: 'settings/CHANGE_SETTING',
          payload: {
            setting: 'logo',
            value: logo,
          },
        })
      }

      // const beacon_value = await GetBeaconValue()
      // if (beacon_value != null && beacon_value != undefined && beacon_value != '') {
      //   if (props.permissions.company_id % 100 < beacon_value && beacon_value != 0) {
      //     setShowBeaconSearch(true)
      //   }
      // }
    }

    FetchRemoteDetails()
  }, [user])

  const setTheme = nextTheme => {
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'swipedark' ? 'swipedark' : 'light',
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'logo',
        value:
          nextTheme === 'swipedark'
            ? '/resources/images/logo4-dark.svg'
            : '/resources/images/logo4.svg',
      },
    })
  }

  const updateRenewalDates = async () => {
    const renewal_dates = await getAPIData('sales', 'check_renewal')
    if (renewal_dates) {
      setIsRenewal(true)
      setRenewalDates({
        start_date: dayjs(renewal_dates.start_date).format('DD MMM YYYY'),
        end_date_1_year: dayjs(renewal_dates.end_date_1_year).format('DD MMM YYYY'),
        end_date_3_years: dayjs(renewal_dates.end_date_3_years).format('DD MMM YYYY'),
      })
    } else {
      setIsRenewal(false)
    }
  }

  useEffect(() => {
    if (store.get('days_left') <= 15) {
      updateRenewalDates()
    } else {
      setIsRenewal(false)
    }
  }, [])

  // useEffect(() => {
  //   const data = await getAPIData('utils', 'get_download_files')
  //   if (data) {
  //     setdownloadList(data.data)
  //   }
  // }, [])

  useEffect(() => {
    if (store.get('features_shown') == undefined) {
      store.set('features_shown', '')
    }
    if (store.get(`app.settings.tutorialTouched`) && stateCompanies?.companies.length <= 2) {
      if (stateCompanies.companies.length > 0 && stateCompanies.companies[0].company_id != 0) {
        setTipVisible(true)
      }
    }
  })

  const customerRef = useRef()
  const vendorRef = useRef()
  const productRef = useRef()
  const inventoryFormRef = useRef(null)
  const payInRef = useRef()
  const subscriptionPlanRef = useRef()
  const handleRef = ref => {
    subscriptionRef.current = ref
  }
  const expenseFormRef = useRef()

  const handleMenuClick = e => {
    // setVisible(false)
    setLoading(true)
    stateCompanies.companies.every(async (element, index, array) => {
      if (element['company_id'] == e) {
        let req = {
          company_id: element.company_id,
        }

        if (!store.get('device_hash')) {
          const device_hash = uuidv4()
          await store.set('device_hash', device_hash)
        }

        const data = await getAPI('user', 'get_token', req)
        if (data.success) {
          store.set('accessToken', data.token)
          store.set('selectedCompany', element)
        }
        const data2 = await currentAccount()
        await dispatch({
          type: 'user/SET_STATE',
          payload: {
            selectedCompany: element,
          },
        })
        store.set('page', 0)
        window.location.reload()
        return false
      }
      return true
    })
  }

  const dropdownMenu = (
    <Menu label="Quick Create" onClick={() => {}}>
      <Menu.Item disabled>
        <span className="font-size-18 font-weight-bold text-gray-900">Create</span>
      </Menu.Item>
      <Menu.Divider />
      {permissions.sales == 1 && (
        <Menu.Item>
          <Link to="/create/invoice" className="arrow">
            {/*<i className="fa-regular fa-file-invoice-dollar mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Invoice</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.purchases == 1 && (
        <Menu.Item>
          <Link to="/create/purchase" className="arrow">
            {/*<i className="fa-regular fa-file-plus mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Purchase Invoice</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.purchase_orders == 1 && (
        <Menu.Item>
          <Link to="/create/purchase_order" className="arrow">
            {/*<i className="fa-regular fa-file-invoice mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Purchase Order</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.estimates == 1 && (
        <Menu.Item>
          <Link to="/create/estimate" className="arrow">
            {/*<i className="fa-regular fa-memo mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Quotation</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.pro_forma_invoices == 1 && (
        <Menu.Item>
          <Link to="/create/pro_forma_invoice" className="arrow">
            {/*<i className="fa-regular fa-memo-circle-check mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Pro Forma Invoice</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {user?.selectedCompany?.country_code == 'IN' && permissions.delivery_challans == 1 && (
        <Menu.Item>
          <Link to="/create/delivery_challan" className="arrow">
            {/*<i className="fa-regular fa-truck mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Delivery Challan</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.sales_returns == 1 && (
        <Menu.Item>
          <Link to="/create/sales_return" className="arrow">
            {/*<i className="fa-regular fa-file-circle-xmark mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Sales Return</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.purchase_returns == 1 && (
        <Menu.Item>
          <Link to="/create/purchase_return" className="arrow">
            {/*<i className="fa-regular fa-file-minus mr-3 fa-fw" />*/}
            <span className="flex justify-between items-center">
              <span>Purchase Return</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link>
        </Menu.Item>
      )}
      {permissions.expenses == 1 && (
        <>
          <Menu.Item>
            <div
              className="arrow"
              onClick={event => {
                closeDropdownSlowly()
                expenseFormRef.current.onAdd()
              }}
            >
              <span className="flex justify-between items-center">
                <span>Expense</span>
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </span>
            </div>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {permissions.customers == 1 && (
        <Menu.Item>
          {/* <Link onClick={event => customerRef.current.onAdd()} className="arrow">
            <span className="flex justify-between items-center">
              <span>Customer</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link> */}
          <div
            className="arrow"
            onClick={event => {
              closeDropdownSlowly()
              customerRef.current.onAdd()
            }}
          >
            <span className="flex justify-between items-center">
              <span>Customer</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </div>
        </Menu.Item>
      )}
      {permissions.vendors == 1 && (
        <Menu.Item>
          {/* <Link onClick={event => vendorRef.current.onAdd()} className="arrow">
            <span className="flex justify-between items-center">
              <span>Vendor</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </Link> */}
          <div
            className="arrow"
            onClick={event => {
              closeDropdownSlowly()
              vendorRef.current.onAdd()
            }}
          >
            <span className="flex justify-between items-center">
              <span>Vendor</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </div>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item>
        <div
          className="arrow"
          onClick={event => {
            closeDropdownSlowly()
            payInRef.current.onAdd({}, undefined, true)
          }}
        >
          <span className="flex justify-between items-center">
            <span>Pay In</span>
            <i className="fa-solid fa-arrow-right ml-2"></i>
          </span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          className="arrow"
          onClick={event => {
            closeDropdownSlowly()
            payInRef.current.onAdd({}, undefined, false)
          }}
        >
          <span className="flex justify-between items-center">
            <span>Pay Out</span>
            <i className="fa-solid fa-arrow-right ml-2"></i>
          </span>
        </div>
      </Menu.Item>
      <Menu.Divider />
      {permissions.products == 1 && (
        <Menu.Item>
          <div
            className="arrow"
            onClick={event => {
              closeDropdownSlowly()
              productRef.current.onAdd()
            }}
          >
            <span className="flex justify-between items-center">
              <span>Product</span>
              <i className="fa-solid fa-arrow-right ml-2"></i>
            </span>
          </div>
        </Menu.Item>
      )}

      {!permissions.serial_number == 1 && (
        <>
          <Menu.Item>
            <div
              className="arrow"
              onClick={event => {
                closeDropdownSlowly()
                inventoryFormRef.current && inventoryFormRef.current.onFill({}, true, undefined)
              }}
            >
              <span className="flex justify-between items-center">
                <span>Stock In</span>
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </span>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div
              className="arrow"
              onClick={event => {
                closeDropdownSlowly()
                inventoryFormRef.current && inventoryFormRef.current.onFill({}, false, undefined)
              }}
            >
              <span className="flex justify-between items-center">
                <span>Stock Out</span>
                <i className="fa-solid fa-arrow-right ml-2"></i>
              </span>
            </div>
          </Menu.Item>
        </>
      )}
    </Menu>
  )

  const downloadMenu = (
    <Menu>
      <Menu.Item>
        <span className="ml-0 pl-0 font-size-18 font-weight-bold">Downloads</span>
      </Menu.Item>

      {downloadList.map((item, index) => (
        <Menu.Item key={index}>
          <div>
            <a href={item.download_url} target={`_blank`} className="ml-0 pl-0 mt-1">
              <div className="font-weight-bold">
                {item.type
                  .split('_')
                  .join(' ')
                  .toUpperCase()}{' '}
              </div>
              <div className="font-weight-normal text-gray-900 font-size-12">{item.date_range}</div>
            </a>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleVisibleChange = flag => {
    setTipVisible(false)
    setFilteredCompanies(stateCompanies.companies)
    setTotalCompanies(stateCompanies.companies.length)
    setCompaniesList(stateCompanies.companies.slice(0, 9))
    setVisible(flag)
  }
  const handlePageChange = page => {
    const startIndex = (page - 1) * 9
    const endIndex = startIndex + 9
    setCurrentPage(page)
    setCompaniesList(filteredCompanies.slice(startIndex, endIndex))
  }
  const handleSearch = value => {
    let list = stateCompanies.companies.filter(
      item =>
        item.company_name.toLowerCase().includes(value.toLowerCase()) ||
        item.organization_name.toLowerCase().includes(value.toLowerCase()),
    )
    setTotalCompanies(list.length)
    setFilteredCompanies(list)
    setCompaniesList(list.slice(0, 9))
    setCurrentPage(1)
  }

  const handleDropdownVisibleChange = visible => {
    setIsDropdownVisible(visible)
  }

  const closeDropdownSlowly = () => {
    setTimeout(() => {
      setIsDropdownVisible(false)
    }, 300)
  }

  const addNewBussiness = () => {
    if (store.get('paid') === 0) {
      // if (subscriptionRef.current) {
      //   subscriptionRef.current.showModal()
      // }
      subscriptionPlanRef.current.openModal('ewaybill', 4)
    } else {
      setVisible(false)
      if (store.get('add_companies') <= 0) {
        window.open(
          'https://api.whatsapp.com/send/?phone=918121335436&text=Hello%20Swipe! I want to add New Company. Please give me details for Business Plan.',
          '_self',
        )
      } else {
        history.push('/add_new_company')
      }
    }
  }
  const handleInventoryRef = ref => {
    inventoryFormRef.current = ref
  }
  const handleCustomerRef = ref => {
    customerRef.current = ref
  }
  const handleExpenseRef = ref => {
    expenseFormRef.current = ref
  }
  useEffect(() => {
    return () => {
      inventoryFormRef.current = null
      customerRef.current = null
      expenseFormRef.current = null
    }
  }, [])

  const loadBeacon = async () => {
    if (is_beacon_loaded) {
      Beacon.open()
    }
    if (!is_beacon_loaded) {
      await Beacon.load(config.url.BEACON_ID)
      await Beacon.loadUser(store.get('user_id'), {
        paid: store.get('paid'),
      })
      const id = setTimeout(() => {
        Beacon.open()
        clearTimeout(id)
      }, 500)
      dispatch(setBeacon(true))
    }
  }

  const handleAISearch = () => {
    setIsAIModalVisible(true)
  }

  return (
    <>
      <div className={`${style.topbar} flex justify-between`}>
        <div className="flex items-center space-x-4">
          <a href="/list/sales" className="flex items-center mr-3">
            <img src={logo} className="h-8 rounded-md" alt="Swipe" />
            {/* <img src={'/resources/images/rakhi.gif'} className="h-8" alt="Swipe" /> */}
          </a>
          <ChangeCompany />
        </div>

        <div className="flex items-center">
          {[71, 96125, 1, 17141].includes(store.get('company_details')?.company_id) && (
            <div className="relative mr-4">
              <Input
                placeholder="Ask anything or search docs..."
                value={aiSearchText}
                onChange={e => setAiSearchText(e.target.value)}
                onClick={handleAISearch}
                style={{
                  width: '320px',
                  padding: '8px 36px 8px 12px',
                  borderRadius: '25px',
                  border: '1px solid #e0e0e0',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                  fontSize: '14px',
                  transition: 'all 0.3s ease',
                }}
                className="focus:border-blue-400 focus:shadow-md hover:border-gray-400"
              />
              {/* <svg
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 cursor-pointer text-indigo-400 hover:text-indigo-500 transition-colors"
              viewBox="0 0 20 20"
              fill="currentColor"
              onClick={handleAISearch}
            >
              <path d="M5 2a1 1 0 011 1v1h1a1 1 0 010 2H6v1a1 1 0 01-2 0V6H3a1 1 0 010-2h1V3a1 1 0 011-1zm0 10a1 1 0 011 1v1h1a1 1 0 110 2H6v1a1 1 0 11-2 0v-1H3a1 1 0 110-2h1v-1a1 1 0 011-1zM12 2a1 1 0 01.967.744L14.146 7.2 17.5 9.134a1 1 0 010 1.732l-3.354 1.935-1.18 4.455a1 1 0 01-1.933 0L9.854 12.8 6.5 10.866a1 1 0 010-1.732l3.354-1.935 1.18-4.455A1 1 0 0112 2z" />
            </svg> */}
            </div>
          )}

          <CustomPricing
            ref={customPricingRef}
            onSelectPlan={(plan, years, addons) => displayRazorpay(plan, years, addons)}
            is_renewal={is_renewal}
            renewal_dates={renewal_dates}
          />

          {props.permissions.intercom == 1 && <IntercomSupportProvider size="small" />}

          <Tooltip title="Create" trigger={['hover']} placement="top">
            <Dropdown
              trigger={['click']}
              overlay={dropdownMenu}
              visible={isCreateDropdownVisible}
              onVisibleChange={handleDropdownVisibleChange}
              overlayStyle={{ minWidth: 256 }}
            >
              <Button type="text" className="px-2 ml-1 rounded-full">
                <i className="fa-solid fa-bolt fa-fw"></i>
              </Button>
            </Dropdown>
            {isCreateDropdownVisible && <div className="fixed inset-0 bg-black bg-opacity-25" />}
          </Tooltip>

          <Notifications
            count={props.notification_count}
            state={props}
            setNotificationCount={count => {
              dispatch(set_notification_count(count))
            }}
            updateNotificationCount={count => {
              dispatch(update_notification_count(count))
            }}
          />
          <Actions state={user.selectedCompany.state} is_paid={user.paid} />
          <UserMenu />
        </div>
      </div>

      <CustomerForm
        onRef={handleCustomerRef}
        ref={customerRef}
        onAPICalled={(a, b, c) => {}}
        permissions={permissions}
        states={props?.states}
        tds_sections={props?.tds_sections}
        countries={props?.countries}
        dispatch={dispatch}
      />
      <VendorForm
        onRef={() => {}}
        ref={vendorRef}
        onAPICalled={(a, b) => {}}
        permissions={permissions}
        states={props?.states}
        tds_sections={props?.tds_sections}
        countries={props?.countries}
        dispatch={dispatch}
      />
      <ProductForm
        onRef={() => {}}
        ref={productRef}
        onAPICalled={(a, b) => {}}
        dispatch={dispatch}
      />
      <PayInForm onRef={() => {}} ref={payInRef} show_party_selection={true} />
      <InventoryForm onRef={handleInventoryRef} onAPICalled={(a, b) => {}} />
      <EnhancedSubscriptionModal onRef={handleRef} />
      <SubscriptionPlanModal ref={subscriptionPlanRef} />
      <ExpenseForm onRef={handleExpenseRef} document_type="expense" onAPICalled={() => {}} />
      <AIFileAssistant
        visible={isAIModalVisible}
        onClose={() => setIsAIModalVisible(false)}
        initialSearchText={aiSearchText}
      />
    </>
  )
}

export default withRouter(connect(mapStateToProps)(TopBar))

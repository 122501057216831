import React, { useState, useRef, useEffect } from 'react'
import { Input, Button, Modal, List, Tooltip, message } from 'antd'
import { SendOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons'
import ReactMarkdown from 'react-markdown'
import { config } from 'services/axios'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import store, { set } from 'store'
import { getAPI } from 'services/jwt'

const CLIENT_REQUEST_ID = 'X-Request-ID'
const REQUEST_TIMESTAMP = 'Request-Timestamp'
const WEB = 'web'
const CUSTOM_HEADERS = {
  source: WEB,
}
// const accessToken = store.get('accessToken')
// const agentAccessToken = store.get('agentAccessKey')

const AIFileAssistant = ({ visible, onClose, initialSearchText }) => {
  const [searchText, setSearchText] = useState(initialSearchText || '')
  const [messageHistory, setMessageHistory] = useState([])
  const [feedbackGiven, setFeedbackGiven] = useState({})
  const [showFeedback, setShowFeedback] = useState(false)
  const [threadId, setThreadId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const chatContainerRef = useRef(null)
  const inputRef = useRef(null)

  const sampleQueries = [
    'How to add image to a Product?',
    'How to add Google review link on web?',
    'How to make an E-way bill?',
  ]

  useEffect(() => {
    const fetchThreadId = async () => {
      try {
        const response = await getAPI('ai', 'create_thread')
        const newThreadId = response.thread_id
        // console.log('New Thread ID = ' + newThreadId)
        setThreadId(newThreadId)
      } catch (error) {
        console.error('Error creating new thread:', error)
      }
    }

    if (threadId === null) {
      fetchThreadId()
    }
  }, [threadId])

  useEffect(() => {
    if (visible && initialSearchText) {
      setSearchText(initialSearchText)
      handleSearch()
      inputRef?.current?.focus()
    }
    if (visible) {
      inputRef?.current?.focus() // Focus on the textarea when the modal is visible
    }
  }, [visible, initialSearchText])

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messageHistory])

  const handleSearch = async () => {
    if (!searchText.trim() || isLoading || isTyping) return

    setIsLoading(true)
    setMessageHistory(prevHistory => [
      ...prevHistory,
      { type: 'user', content: searchText, timestamp: new Date() },
    ])
    setSearchText('')

    try {
      // Open the connection to your streaming API endpoint
      console.log('API URL = ' + config.url.API_URL + '/ai/streaming_file_assistant')
      console.log('Client Request id = ' + WEB + '-' + uuidv4())
      const response = await fetch(config.url.API_URL + '/ai/streaming_file_assistant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...CUSTOM_HEADERS,
          [CLIENT_REQUEST_ID]: WEB + '-' + uuidv4(),
          [REQUEST_TIMESTAMP]: dayjs(),
          DeviceHash: store.get('device_hash'),
          Authorization: `Bearer ${store.get('accessToken')}`,
          AgentAuthorization: `Bearer ${store.get('agentAccessKey')}`,
        },
        body: JSON.stringify({
          query: searchText,
          thread_id: threadId,
        }),
      })

      if (!response.body) throw new Error('No response body from streaming API')

      const reader = response.body.getReader()
      const decoder = new TextDecoder()
      let responseText = ''
      let currentMessageIndex = messageHistory.length + 1

      // Add an empty message object for AI response in the state
      setMessageHistory(prevHistory => [
        ...prevHistory,
        { type: 'ai', content: '', sources: [], timestamp: new Date() },
      ])

      while (true) {
        setIsLoading(false)
        setShowFeedback(false)
        const { done, value } = await reader.read()
        responseText += new TextDecoder().decode(value)

        // Update the message with the new chunk of data
        setMessageHistory(prevHistory => {
          const newHistory = [...prevHistory]
          newHistory[currentMessageIndex] = {
            ...newHistory[currentMessageIndex],
            content: responseText, // Append the streamed text
          }
          return newHistory
        })
        if (done) {
          setIsTyping(false)
          inputRef?.current?.focus()
          setShowFeedback(true)
          return
        }
      }

      // Complete the streaming and stop loading
    } catch (error) {
      console.error('Error calling AI service:', error)
      setIsLoading(false)
      setMessageHistory(prevHistory => [
        ...prevHistory,
        { type: 'error', content: 'An error occurred. Please try again.', timestamp: new Date() },
      ])
    }
  }

  const handleFeedback = async (messageIndex, isLike) => {
    if (feedbackGiven[messageIndex]) return // Prevent multiple feedback submissions

    const user_message = messageHistory[messageIndex]
    const feedback = isLike ? 1 : -1

    try {
      const response = await getAPI('ai', 'chat_feedback', {
        answer: user_message.content,
        feedback: feedback,
        thread_id: threadId,
      })
      if (response.success) {
        message.success(response.message)
      }
      //console.log('Feedback submitted:', response)
    } catch (error) {
      console.error('Error submitting feedback:', error)
      // Optionally, show an error message to the user
    }
  }

  const renderMessage = (message, index) => (
    <div
      key={index}
      className={`flex flex-row message ${message.type} ${
        message.type === 'user' ? 'justify-end' : 'justify-start'
      } mb-4`}
    >
      <div
        style={{
          maxWidth: '70%',
          padding: '12px 16px',
          borderRadius: '18px',
        }}
        className={`font-size-16 ${message.type === 'user' ? 'bg-gray-75' : 'bg-white'}`}
      >
        {message.type === 'ai' ? (
          <>
            <div className="flex flex-start">
              <div className="mr-4">
                <div
                  className="rounded-full flex items-center justify-center border"
                  style={{ width: '40px', height: '40px' }}
                >
                  <i className="fa far fa-sparkles fa-lg"></i>
                </div>
              </div>
              <div className="pt-2">
                <ReactMarkdown
                  components={{
                    // Convert links to open in a new tab and add underline
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        style={{ textDecoration: 'underline', lineHeight: '1.5' }}
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    h1: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-4"
                        style={{ lineHeight: '2' }}
                        {...props}
                      />
                    ),
                    h2: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-4"
                        style={{ lineHeight: '1.9' }}
                        {...props}
                      />
                    ),
                    h3: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-2"
                        style={{ lineHeight: '1.8' }}
                        {...props}
                      />
                    ),
                    h4: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-2"
                        style={{ lineHeight: '1.7' }}
                        {...props}
                      />
                    ),
                    h5: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold my-2"
                        style={{ lineHeight: '1.6' }}
                        {...props}
                      />
                    ),
                    h6: ({ node, ...props }) => (
                      <p
                        className="font-size-20 font-weight-bold text-gray-400 my-2"
                        style={{ lineHeight: '1.5' }}
                        {...props}
                      />
                    ),
                    // Handle ** text as bold
                    strong: ({ node, ...props }) => (
                      <strong
                        className="font-weight-medium text-gray-800"
                        style={{ lineHeight: '1.5' }}
                        {...props}
                      />
                    ),
                    // Handle normal text
                    p: ({ node, ...props }) => (
                      <p className="my-1 text-gray-500" style={{ lineHeight: '1.5' }} {...props} />
                    ),
                    // Handle normal text
                    span: ({ node, ...props }) => (
                      <>
                        <span
                          className="my-1 text-gray-500"
                          style={{ lineHeight: '1.5' }}
                          {...props}
                        />
                      </>
                    ),
                    li: ({ node, ...props }) => (
                      <>
                        <li
                          className="my-1 text-gray-500"
                          style={{ lineHeight: '1.5' }}
                          {...props}
                        />
                      </>
                    ),
                    ul: ({ node, ...props }) => (
                      <>
                        <ul
                          className="my-1 text-gray-500"
                          style={{ lineHeight: '1.5' }}
                          {...props}
                        />
                      </>
                    ),
                    ol: ({ node, ...props }) => (
                      <>
                        <ol
                          className="my-1 text-gray-500"
                          style={{ lineHeight: '1.5' }}
                          {...props}
                        />
                      </>
                    ),
                  }}
                >
                  {message.content.replace(/【\d+:\d+†\w+】/g, '')}
                </ReactMarkdown>
                {index === messageHistory.length - 1 &&
                  message.type === 'ai' &&
                  showFeedback === true && (
                    <div className="flex justify-end mt-2">
                      <Tooltip title="Helpful">
                        <Button
                          icon={<LikeOutlined />}
                          size="small"
                          type="text"
                          onClick={() => handleFeedback(index, true)}
                          disabled={feedbackGiven[index]}
                        />
                      </Tooltip>
                      <Tooltip title="Not helpful">
                        <Button
                          icon={<DislikeOutlined />}
                          size="small"
                          type="text"
                          onClick={() => handleFeedback(index, false)}
                          disabled={feedbackGiven[index]}
                        />
                      </Tooltip>
                    </div>
                  )}
              </div>
            </div>
          </>
        ) : (
          <p style={{ margin: 0 }}>{message.content}</p>
        )}
      </div>
      {/* <span style={{ fontSize: '0.75rem', color: '#8E8E93', marginTop: '4px' }}>
        {message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
      </span> */}
    </div>
  )

  const renderThinkingAnimation = () => (
    <div
      className="flex flex-start"
      style={{
        maxWidth: '70%',
        padding: '12px 16px',
        borderRadius: '18px',
      }}
    >
      <div className="mr-4">
        <div
          className="rounded-full flex items-center justify-center border"
          style={{ width: '50px', height: '50px' }}
        >
          <i className="fa far fa-sparkles fa-lg"></i>
        </div>
      </div>
      <div className="thinking-animation flex flex-start mb-4 mt-2">
        <div className="circle bg-gray-500"></div>
        <div className="circle bg-gray-500"></div>
        <div className="circle bg-gray-500"></div>
      </div>
    </div>
  )

  // Only call handleSearch when searchText is updated and triggerSearch is true
  useEffect(() => {
    if (triggerSearch) {
      handleSearch()
      setTriggerSearch(false) // Reset the flag after handling the search
    }
  }, [searchText, triggerSearch])

  const handleSampleQueryClick = query => {
    setSearchText(query)
    setTriggerSearch(true) // Set flag to trigger search
  }

  const renderSampleQueries = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <h4 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Try asking about</h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '10px',
          width: '100%',
        }}
      >
        {sampleQueries.map((query, index) => (
          <Tooltip key={index} title="Click to use this query">
            <Button
              onClick={() => handleSampleQueryClick(query)}
              style={{
                borderRadius: '12px',
                background: '#F0F0F0',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'all 0.3s ease',
                padding: '24px',
                fontSize: '14px',
                fontWeight: '500',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onMouseOver={e => {
                e.currentTarget.style.background = '#E0E0E0'
                e.currentTarget.style.transform = 'translateY(-2px)'
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.15)'
              }}
              onMouseOut={e => {
                e.currentTarget.style.background = '#F0F0F0'
                e.currentTarget.style.transform = 'none'
                e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)'
              }}
            >
              {query}
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Ask anything or search docs...'}
      visible={visible}
      onCancel={onClose}
      footer={null}
      // width={1080}
      width={'70vw'}
      centered
    >
      <div
        ref={chatContainerRef}
        className="px-4 mx-4 bg-white"
        style={{
          height: '70vh',
          overflowY: 'auto',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {messageHistory.length === 0 ? (
          renderSampleQueries()
        ) : (
          <List dataSource={messageHistory} renderItem={renderMessage} />
        )}
        {isLoading && renderThinkingAnimation()}
      </div>
      <div className="mb-2 mt-4 pl-4 ml-4 pr-4" style={{ position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input.TextArea
            ref={inputRef}
            placeholder="Ask anything or search docs..."
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onPressEnter={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSearch()
              }
            }}
            size="large"
            autoSize={{ minRows: 1, maxRows: 4 }}
            style={{ borderRadius: '20px', resize: 'none' }}
            disabled={isLoading || isTyping}
            className="bg-gray-75"
            autoFocus
          />
          <Button
            type="link"
            size="large"
            className="ml-1"
            icon={<SendOutlined style={{ fontSize: '24px' }} />}
            onClick={handleSearch}
            disabled={isLoading || isTyping || !searchText.trim()}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <span className="font-size-12 text-gray-400">
          This is AI generated response. Please double check before using it.
        </span>
      </div>
      <style jsx>{`
        .thinking-animation {
          display: flex;
          align-items: center;
        }
        .circle {
          width: 10px;
          height: 10px;
          margin: 0 5px;
          border-radius: 50%;
          animation: pulse 1.5s infinite ease-in-out;
        }
        .circle:nth-child(2) {
          animation-delay: 0.2s;
        }
        .circle:nth-child(3) {
          animation-delay: 0.4s;
        }
        @keyframes pulse {
          0%,
          100% {
            transform: scale(0.5);
            opacity: 0.5;
          }
          50% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </Modal>
  )
}

export default AIFileAssistant

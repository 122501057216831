import {
  Affix,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Dropdown,
  Modal,
  Pagination,
  Select,
  Table,
  Tooltip,
  message,
} from 'antd'
import EnhancedCustomerFrom from 'components/forms/customerForm'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import { EnhancedMailFrom } from 'components/forms/mailForm'
import PayInForm from 'components/forms/payForm'
import EditPayInForm from 'components/forms/paymentEditForm'
import EnhancedVendorFrom from 'components/forms/vendorform'
import { Loader } from 'components/loaders'
import { EnhancedCreate } from 'components/modal/create'
import { EnhancedDelete } from 'components/modal/delete'
import EnhancedExport from 'components/modal/export'
import PartyLedgerOptions from 'components/modal/ledger/partyLedgerOptions'
import GetPaymentDocument from 'components/other/GetPaymentDocument'
import ShowDateString from 'components/other/dateString'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { MyDocument } from 'pages/doc/view'
import React, { Component } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { get_payment_modes } from 'redux/document/actions'
import { config } from 'services/axios'
import { downloadAPI, getAPI } from 'services/jwt'
import store from 'store'
import { LedgerDocument } from './ledgerPDF'
import { UserAvatar } from 'components/other/utility'
// import Ledger from 'components/ledger/index'

dayjs.extend(utc)

const { Option } = Select
const { RangePicker } = DatePicker

class Ledger extends Component {
  dateFormat = 'DD-MM-YYYY'
  selectDateRef = React.createRef()
  constructor(props) {
    super(props)
    this.paymentRef = React.createRef()
    // this.selectDateRef = React.createRef()
    this.state = {
      data: [],
      utilts: {},
      num_records: 10,

      dates:
        dayjs()
          .month(0)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .month(11)
          .endOf('month')
          .format(this.dateFormat),
      transactions_total: 0,
      page: 0,
      total: 0,
      search: '',
      edit: false,
      invoiceData: '',
      invoiceFlag: false,
      type: ['ORIGINAL FOR RECIPIENT'],
      myKey: 0,
      selectedUser: '',
      pdfData: '',
      pdfFlag: false,
      loading: true,
      visible: false,
      show_ledger_pdf: false,
      order: 'desc',
      document_type: 'invoice',
      shareVisible: false,
      showCustomDateRange: false,
      optionCheckbox: [],
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    if (this.props?.utilts?.payment_modes?.length == 0) {
      this.props.dispatch(get_payment_modes())
    }
  }

  callData = async (page, records) => {
    this.setState({ loading: true })
    setTimeout(async () => {
      const req = {
        num_records: records,
        page: page,
        id: this.props.selectedUser.id,
        date: this.state.dates,
        order: this.state.order,
        show_pending_docs: this.state.show_pending_docs,
        show_custom_date_range: this.state.showCustomDateRange,
      }
      const data = await getAPI(this.props.type, 'ledger', req)
      if (data) {
        const results = data.ledger.map((row, index) => ({
          key: index,
          ...row,
        }))
        this.setState(
          {
            data: results,
            total: data.total_records,
            customer_id: this.props.selectedUser.id,
            page: page,
            num_records: records,
            transactions_total: data.closing_balance,
            edit: data.edit,
            selectedUser: {
              ...this.props.selectedUser,
              name: data.party_details.name,
              balance: data.party_details.balance,
              phone: data.party_details.phone,
              opening_balance: data.party_details.opening_balance,
              hash_id: data.hash_id,
              type: this.props.type,
            },
            showCustomDateRange: data.show_custom_date_range,
          },
          async () => {
            // await this.props.callData(0, this.state.num_records)
            this.setState({ loading: false })
            // if (shareVisible) {
            //   this.setState({ shareVisible: true, show_pending_docs: showPendingDocs })
            // }
          },
        )
      }
    }, 100)
  }
  changePendingDocs = value => {
    this.setState({ show_pending_docs: value })
  }
  changeDates = value => {
    this.setState({ dates: value })
  }
  changeShareVisible = () => {
    this.setState({ shareVisible: true })
  }
  changeCustomDateRange = value => {
    this.setState({ showCustomDateRange: value })
  }

  onAPICalled = () => {
    this.callData(this.state.page, this.state.num_records)
  }

  clearData() {
    this.setState({ selectedUser: '', loading: true, data: [] })
  }

  async ledgerDownload(page, records) {
    const req = {
      num_records: records,
      page: page,
      id: this.props.selectedUser.id,
      date: this.state.dates,
    }
    const data = await getAPI(this.props.type, 'ledger', req)
    if (data) {
      const results = data.ledger.map((row, index) => ({
        key: index,
        ...row,
      }))

      this.props.onAPICalled(data)
    }
  }

  onPaginationChange = current => {
    this.setState({ loading: true })
    this.callData(current - 1, this.state.num_records)
  }
  setCancelledModal = () => {
    this.setState({ invoiceFlag: false, invoiceData: '', linkDownload: false })
  }

  getPaidClass = data => {
    switch (data) {
      case 'Cash':
        return 'cash'
      case 'Card':
        return 'card'
      case 'UPI':
        return 'upi'
      case 'EMI':
        return 'emi'
      case 'Net Banking':
        return 'netbanking'
      case 'Cheque':
        return 'cheque'
      case 'paylater':
        return 'paylater'
      case 'TDS':
        return 'tds'
      default:
        return 'card'
    }
  }

  getPaymentClass = data => {
    switch (data) {
      case 'open':
        return 'warning'
      case 'partial':
        return 'partial'
      case 'closed':
        return 'success'
      case 'pending':
        return 'warning'
      case 'paid':
        return 'success'
      case 'cancelled':
        return 'danger'
      case 'partially paid':
        return 'partial'
      default:
        return 'danger'
    }
  }

  handleDelete = async id => {
    const req = {
      serial_number: id,
    }

    const data = await getAPI('payments', 'cancel', req)

    if (data) {
      this.callData(this.state.page, this.state.num_records)
    }
  }
  handle_document_cancel = async (doc, cancel_einvoice) => {
    const req = {
      document_type: this.state.document_type,
      new_hash_id: doc.new_hash_id,
    }

    const data = await getAPI('doc', 'delete', req)

    if (data) {
      this.callData(this.state.page, this.state.num_records)
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return
    }
  }
  getInvoice = async (new_hash_id, flag, dtype) => {
    const req = {
      new_hash_id: new_hash_id,
      document_type: dtype.toLowerCase().replace(' ', '_'),
      is_pdf: true,
    }

    const data = await getAPI('v2/doc', 'get_invoice', req)

    if (data.success) {
      if (flag) {
        var type = ['Delivery Challan']
      } else {
        var type = [data.invoice_details.invoice_settings.labels.original_for_recipient]
      }

      var options = [
        {
          label: 'Customer',
          value: data.invoice_details.invoice_settings.labels.original_for_recipient,
        },
        {
          label: 'Transport',
          value: data.invoice_details.invoice_settings.labels.duplicate_for_transporter,
        },
        {
          label: 'Supplier',
          value: data.invoice_details.invoice_settings.labels.triplicate_for_supplier,
        },
        { label: 'Delivery Challan', value: 'Delivery Challan' },
      ]

      this.setState({
        invoiceData: data.invoice_details,
        challanFlag: flag,
        invoiceFlag: true,
        type: type,
        optionCheckbox: options,
        document_type: dtype.toLowerCase().replace(' ', '_'),
      })
    }
  }
  ledgerPDFView = async data => {
    this.setState({ pdfFlag: true, pdfData: data })
  }

  refreshBalance = async party => {
    var req = {
      party_id: this.props.type == 'customer' ? party.customer_id : party.vendor_id,
    }
    const module = this.props.type == 'customer' ? 'v2/customer' : 'v2/vendor'

    const data = await getAPI(module, 'refresh_balance', req)
    if (data && data.success) {
      message.success(data.message)
      this.callData(this.state.page, this.state.num_records)
    }
  }

  getStatusClass = data => {
    if (data == 'out' || data < 0) {
      return 'bg-negative'
    } else {
      return 'bg-positive'
    }
  }

  getTextClass = data => {
    if (data == 'out' || data < 0) {
      return 'text-danger'
    } else {
      return 'text-forest'
    }
  }

  getMenuName = document_type => {
    switch (document_type) {
      case 'invoice':
        return 'sales'
      case 'purchase':
        return 'purchases'
      case 'sales_return':
        return 'sales_returns'
      case 'purchase_return':
        return 'purchase_returns'
      case 'delivery_challan':
        return 'delivery_challans'
      case 'purchase_order':
        return 'purchase_orders'
      case 'estimate':
        return 'estimates'
      case 'pro_forma_invoice':
        return 'pro_forma_invoices'
      default:
        return 'sales'
    }
  }
  get_party_ledger_pdf = async () => {
    const req = {
      id: this.state.customer_id,
      date: this.state.dates,
      all_records: 1,
      order: this.state.order,
      show_pending_docs: this.state.show_pending_docs,
    }
    const data = await getAPI(this.props.type, 'ledger', req)
    if (data) {
      this.setState({ pdfData: data }, () => {
        this.setState({ show_ledger_pdf: true })
      })
    }
  }
  download_party_ledger_excel = async () => {
    const req = {
      customer_id: this.state.customer_id,
      vendor_id: this.state.customer_id,
      date: this.state.dates,
      date_range: this.state.dates,
      order: this.state.order,
      show_pending_docs: this.state.show_pending_docs,
    }
    const data = await downloadAPI(this.props.type + '/ledger', 'download', req)
  }
  handleTableChange = (pagination, filters, sorter) => {
    // console.log(sorter)
    let order = sorter.order
    if (order == 'ascend') {
      order = 'asc'
    } else if (order == 'descend') {
      order = 'desc'
    } else {
      order = 'desc'
    }
    if (this.state.order == order) {
      return
    }
    this.setState({ order: order }, () => {
      this.callData(this.state.page, this.state.num_records)
    })
  }
  has_delete_permission = document_type => {
    if (document_type == 'invoice') {
      return this.props.permissions.invoice_delete
    } else if (document_type == 'sales_return') {
      return this.props.permissions.sales_return_delete
    } else if (document_type == 'purchase_return') {
      return this.props.permissions.purchase_return_delete
    } else if (document_type == 'purchase') {
      return this.props.permissions.purchase_delete
    } else if (document_type == 'expense') {
      return this.props.permissions.expense_delete
    }
    return 0
  }
  render() {
    // console.log(this.props);
    const { selectedUser } = this.state
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    let ActionItems = [
      // {
      //   key: '1',
      //   label: (
      //     <a
      //       type="whatsapp-outline"
      //       size="small"
      //       className="font-weight-bold"
      //       style={{ height: '24px', borderRadius: '8px' }}
      //       onClick={event => {
      //         event.stopPropagation()
      //         window.open(
      //           'https://api.whatsapp.com/send/?phone=' +
      //             selectedUser.dial_code +
      //             selectedUser.phone +
      //             '&text=Hello%20*' +
      //             selectedUser.name +
      //             '*%2C%20%0A%0APlease%20find%20your%20transactions%20here%2C%20%0A' +
      //             config.url.short_URL +
      //             '/view/ledger/' +
      //             selectedUser.type +
      //             '/' +
      //             selectedUser.hash_id +
      //             '%0A%0AThanks%0A*' +
      //             store.get('company_name') +
      //             '*%0A*' +
      //             store.get('company_mobile') +
      //             '*' +
      //             (store.get('paid') == 0
      //               ? '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)'
      //               : ''),
      //         )
      //       }}
      //     >
      //       <i className="fa-brands fa-whatsapp fa-lg fa-fw text-whatsapp mr-2" />
      //       <span className="float-right">Whatsapp</span>
      //     </a>
      //   ),
      // },
      {
        key: '1',
        label: (
          <a
            className="font-weight-medium font-size-14"
            size="small"
            onClick={() => {
              this.selectDateRef?.current?.openShareModal(
                this.state.show_pending_docs,
                this.state?.num_records,
                this.state?.page,
                this.state?.showCustomDateRange,
              )
            }}
          >
            <i className="fa-regular fa-paper-plane fa-fw mr-2"></i>
            Share
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a
            size="small"
            className="font-weight-medium font-size-14"
            onClick={e => {
              if (this.props.type == 'customer') {
                this[this.props.type].onFill(selectedUser.id)
              } else {
                this[this.props.type].onFill(selectedUser)
              }
            }}
          >
            <i className="fa far fa-edit fa-lg fa-fw mr-2" />
            <span>Edit</span>
          </a>
        ),
      },
      this.props.permissions.invoice_edit &&
        this.props.type == 'customer' && {
          key: '3',

          label: (
            <a
              onClick={() => {
                this.setState({ visible: true }, () => this.create.showModal('create'))
              }}
              className="font-weight-medium font-size-14"
              size="small"
            >
              <i className="fa far fa-file-invoice-dollar fa-fw fa-lg mr-2" />
              <span>Invoice</span>
            </a>
          ),
        },
      // {
      //   key: '4',
      //   label: (
      //     <CopyToClipboard
      //       text={
      //         config.url.short_URL +
      //         '/view/ledger/' +
      //         selectedUser.type +
      //         '/' +
      //         selectedUser.hash_id
      //       }
      //       onCopy={() => message.success('Copied')}
      //     >
      //       <a
      //         onClick={event => {
      //           event.stopPropagation()
      //         }}
      //       >
      //         <i className="fa-duotone fa-clone fa-lg text-gray-500 mr-2 fa-fw" />
      //         Copy link
      //       </a>
      //     </CopyToClipboard>
      //   ),
      // },
    ]
    if (this.props.permissions.customer_edit == 0) {
      ActionItems = ActionItems.filter(item => item.key != '2')
    }
    const columns = [
      {
        title: <FormattedMessage id="table.id" />,
        width: '300px',
        dataIndex: 'serial_number',
        render: (text, data) => (
          <div>
            <span className={'text-gray-700 mb-0 font-size-14 font-weight-bold'}>
              {text}
              {data.supplier_invoice_serial_number && (
                <Tooltip title="Supplier Invoice Serial Number">
                  <span className="ml-2"> ({data.supplier_invoice_serial_number})</span>
                </Tooltip>
              )}
            </span>
            <span>
              <div className="font-size-12 text-gray-700 mb-0 font-weight-medium">{data.type}</div>
            </span>
            <span className="block sm:hidden">
              <span className="text-dark ls-2 font-size-14">{data.payment_date}</span>
            </span>
            {data.notes != '' && (
              <span>
                <span className="font-size-12 text-gray-500">{data.notes}</span>
              </span>
            )}
          </div>
        ),
      },
      // Table.EXPAND_COLUMN,
      {
        title: (
          <>
            <span>
              <span className="mt-0 mb-0">
                <FormattedMessage id="table.date" /> /{' '}
              </span>
              <span className="font-size-10">
                <FormattedMessage id="table.createdTime" />
              </span>
            </span>
          </>
        ),
        dataIndex: '',
        align: 'left',
        responsive: ['md'],
        sorter: (a, b) => {},
        render: (text, data) => (
          <span>
            <span className="text-dark ls-2 font-size-14">{data.payment_date}</span>
            <span className="font-size-10 text-gray-700">
              <p className="mb-0 ls-1">{data.created_time}</p>
            </span>
          </span>
        ),
      },
      {
        title: (
          <>
            <span>
              <span className="mt-0 mb-0">
                <FormattedMessage id="table.status" />
              </span>
            </span>
          </>
        ),
        dataIndex: 'payment_status',
        responsive: ['md'],
        render: (text, data) => (
          <>
            {data.payment_status != '' && (
              <>
                <Tooltip
                  placement="topRight"
                  title={
                    data.payment_status == 'partially paid' ? (
                      <span className="text-right">
                        <span className="text-white-strict mt-0 mb-0">Pending Amount</span>
                        <span className="mb-2 mt-0 font-weight-bolder">
                          <p className="mt-0 mb-0">
                            <span className="mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            {data.amount_pending}
                          </p>
                        </span>
                      </span>
                    ) : (
                      ''
                    )
                  }
                >
                  <Button
                    type={this.getPaymentClass(data.payment_status)}
                    size="small"
                    className="mb-0 font-weight-bold mr-1"
                    onClick={event => {
                      event.stopPropagation()
                    }}
                  >
                    {data.payment_status}
                  </Button>
                </Tooltip>
                {(data.payment_status == 'pending' || data.payment_status == 'partially paid') &&
                  dayjs().diff(dayjs(data.due_date), 'days') > 0 && (
                    <p className="m-0 p-0 position-absolute text-danger font-size-10">
                      {dayjs().diff(dayjs(data.due_date), 'days') == 1 ? (
                        <span>
                          since <b>1</b> day
                        </span>
                      ) : (
                        <span>
                          since <b>{dayjs().diff(dayjs(data.due_date), 'days')}</b> days
                        </span>
                      )}
                    </p>
                  )}
              </>
            )}
          </>
        ),
      },
      {
        title: <FormattedMessage id="table.mode" />,
        dataIndex: 'type',
        responsive: ['md'],
        render: (text, data) => (
          <span>
            {data.payment_mode != '' && (
              <Button
                type={this.getPaidClass(data.payment_mode)}
                size="small"
                className={`font-size-14 mr-1 mb-1 mt-1`}
                onClick={() => {
                  if (this.props.permissions.record_payment == 1) {
                    this.edit.onAdd(this.props.utilts, data, this.state.document_type)
                  }
                }}
              >
                <Tooltip
                  title={
                    this.props.permissions.record_payment == 1
                      ? 'Click here for Payment Receipt'
                      : ''
                  }
                >
                  <span className="font-weight-bold font-size-11">{data.payment_mode}</span>
                </Tooltip>
              </Button>
            )}
          </span>
        ),
        hidden: this.state.show_pending_docs,
      },
      {
        title: <FormattedMessage id="table.amount" />,
        dataIndex: 'payment_type',
        responsive: ['xs', 'sm'],
        render: (text, data) => {
          return {
            props: {
              className: this.state.show_pending_docs ? '' : this.getStatusClass(text),
            },
            children: (
              <span className={`font-weight-bold font-size-14`}>
                <span>
                  <span className="font-size-9 mr-1">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {parseFloat(data.total_amount.toFixed(2)).toLocaleString('en-IN', dotOptions)}
                </span>
              </span>
            ),
          }
        },
      },
      {
        title: <FormattedMessage id="table.pendingAmount" />,
        dataIndex: 'payment_type',
        responsive: ['xs', 'sm'],
        render: (text, data) => {
          return {
            props: {
              className: data.type == '' ? '' : this.getStatusClass(text),
            },
            children: (
              <span className={`font-weight-bold font-size-14`}>
                <span>
                  <span className="font-size-9 mr-1">
                    {this.props?.user?.selectedCompany?.currencysymbol}
                  </span>
                  {parseFloat(data.amount_pending.toFixed(2)).toLocaleString('en-IN', dotOptions)}
                </span>
              </span>
            ),
          }
        },
        hidden: !this.state.show_pending_docs,
      },

      {
        title: <FormattedMessage id="table.closingBalance" />,
        dataIndex: 'balance',
        render: (text, data) => {
          return {
            props: {
              className: this.getStatusClass(text),
            },
            children: (
              <span className={`font-weight-bolder ${this.getTextClass(text)}`}>
                <span className="font-size-9 mr-1">
                  {this.props?.user?.selectedCompany?.currencysymbol}
                </span>
                {parseFloat(Math.abs(text.toFixed(2))).toLocaleString('en-IN', dotOptions)}
              </span>
            ),
          }
        },
        hidden: this.state.show_pending_docs,
      },
      {
        title: <FormattedMessage id="table.action" />,
        dataIndex: '',
        align: 'right',
        render: (text, data) => (
          <span onClick={e => e.stopPropagation()}>
            {data.type !== 'Payment In' &&
              data.type !== 'Payment Out' &&
              data.type !== 'Balance' &&
              data.type != 'Expense' && (
                <>
                  {this.has_delete_permission(data.document_type) == 1 && (
                    <Button
                      size="small"
                      type="danger"
                      className="mr-1"
                      onClick={e => {
                        e.stopPropagation()
                        this.setState({ document_type: data.document_type }, () => {
                          this.document_cancel.showModal({
                            ...data,
                            invoice_date: data.payment_date,
                            new_hash_id: data.hash_id,
                            customer: selectedUser,
                          })
                        })
                      }}
                    >
                      <i className="fa-regular fa-trash-can" />
                    </Button>
                  )}
                  <Button
                    type="view-button"
                    size="small"
                    onClick={event => {
                      event.stopPropagation()
                      this.getInvoice(data.hash_id, false, data.type)
                    }}
                    className="font-weight-medium mr-1 ml-1 font-size-12"
                  >
                    <i className="fa fa-eye mr-1 font-size-12" />
                    <FormattedMessage id="button.view" />
                  </Button>
                  <Button
                    type="view-button"
                    size="small"
                    onClick={event => {
                      event.stopPropagation()
                      this.invoiceForm.showDrawer(this.getMenuName(data.document_type), {
                        ...data,
                        new_hash_id: data.hash_id,
                      })
                    }}
                    className="font-weight-medium ml-1 font-size-12"
                  >
                    <i className="fa-regular fa-arrow-up-right-from-square"></i>
                  </Button>
                </>
              )}

            {(data.type == 'Payment In' || data.type == 'Payment Out') && (
              <>
                {this.props.permissions.record_payment == 1 && (
                  <Tooltip title="Payment Details">
                    <Button
                      onClick={() =>
                        this.edit.onAdd(this.props.utilts, data, this.state.document_type)
                      }
                      className="mr-1 ml-1"
                      type="warning"
                      size="small"
                    >
                      <i className="fa fa-edit mr-1" />
                      <FormattedMessage id="button.edit" />
                    </Button>
                  </Tooltip>
                )}
                {this.state.edit && (
                  <Button
                    size="small"
                    type="danger"
                    className="mr-1 ml-1"
                    onClick={event => {
                      event.stopPropagation()
                      this.delete.showModal(data)
                    }}
                  >
                    <i className="fa-regular fa-trash-can" />
                  </Button>
                )}
                <Button
                  type="view-button"
                  size="small"
                  onClick={event => {
                    event.stopPropagation()
                    this.paymentRef.current.showModal(data.serial_number)
                    // this.getInvoice(data.hash_id, false, data.type)
                  }}
                  className="font-weight-medium mr-1 ml-1 font-size-12"
                >
                  <i className="fa fa-eye mr-1 font-size-12" />
                  <FormattedMessage id="button.view" />
                </Button>
                <Button
                  type="view-button"
                  size="small"
                  onClick={event => {
                    event.stopPropagation()
                    this.edit.onAdd(this.props.utilts, data, this.getMenuName(data.document_type))
                  }}
                  className="font-weight-medium ml-1 font-size-12"
                >
                  <i className="fa-regular fa-arrow-up-right-from-square"></i>
                </Button>
              </>
            )}
          </span>
        ),
      },
    ].filter(item => !item.hidden)

    return (
      <div>
        <GetPaymentDocument ref={this.paymentRef} />
        {this.state.pdfData != '' && this.state.pdfFlag && (
          <Modal
            title="Party Ledger"
            open={this.state.pdfFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ pdfFlag: false })}
            onCancel={() => this.setState({ pdfFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button key="back" type="default" onClick={() => this.setState({ pdfFlag: false })}>
                Cancel
              </Button>,
            ]}
          >
            <LedgerDocument
              data={{ ...this.state.pdfData, show_pending_docs: this.state.show_pending_docs }}
            />
          </Modal>
        )}

        <Card size="medium" className="border-radius-small mb-3">
          {!this.state.loading && (
            <div className="row">
              {/* ledger Header */}

              <div className="col-12 col-md-12 mb-4">
                <div className="flex items-center">
                  <div className="mr-auto">
                    {/*<span className="font-weight-bolder text-gray-700 font-size-21 mr-2">*/}
                    <span>
                      <span className="font-weight-bold font-size-16">
                        {selectedUser.company_name != '' && selectedUser.company_name}
                      </span>
                      {selectedUser.is_delete === 1 && (
                        <span className="pl-3 text-danger font-weight-bolder">
                          <i className="fa-solid fa-user-xmark pr-2"></i>
                          Deleted
                        </span>
                      )}
                      <div className="flex items-center">
                        <UserAvatar
                          name={
                            selectedUser.name != '' &&
                            selectedUser.name != selectedUser.company_name &&
                            selectedUser.name
                          }
                        />
                        <div className="ml-2">
                          <span className="font-weight-medium mr-1">
                            {selectedUser.name != '' &&
                              selectedUser.name != selectedUser.company_name &&
                              selectedUser.name}
                          </span>
                          <span className="text-gray-500">
                            {selectedUser.phone != '' && selectedUser.phone}
                          </span>
                          <p className="font-weight-bold font-size-14 mt-0 mb-1">
                            {selectedUser.balance != 0 ? (
                              <span
                                className={
                                  this.props.type == 'customer'
                                    ? `font-weight-bolder ${
                                        selectedUser.balance <= 0 ? 'text-danger' : 'text-forest'
                                      }`
                                    : `font-weight-bolder ${
                                        selectedUser.balance <= 0 ? 'text-forest' : 'text-danger'
                                      }`
                                }
                              >
                                <span className="mr-2">
                                  {selectedUser.balance < 0 ? (
                                    <FormattedMessage id="payments.text.youCollect" />
                                  ) : (
                                    <FormattedMessage id="payments.text.youPay" />
                                  )}
                                </span>{' '}
                                <span className="mr-1">
                                  {this.props?.user?.selectedCompany?.currency_symbol}
                                </span>
                                {Math.round(Math.abs(selectedUser.balance)).toLocaleString(
                                  'en-IN',
                                  dotOptions,
                                )}
                                <Tooltip title="Refresh Balance">
                                  <i
                                    className="fa fa-refresh ml-2"
                                    style={{ cursor: 'pointer', color: '#276ef1' }}
                                    onClick={() => {
                                      this.refreshBalance(selectedUser)
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            ) : (
                              <>
                                <span className="text-primary">Settled</span>
                                <Tooltip title="Refresh Balance">
                                  <i
                                    className="fa fa-refresh ml-2"
                                    style={{ cursor: 'pointer', color: '#276ef1' }}
                                    onClick={() => {
                                      this.refreshBalance(selectedUser)
                                    }}
                                  />
                                </Tooltip>
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div className="flex">
                    <Button
                      onClick={e => this.download_party_ledger_excel()}
                      type="primary"
                      className="mr-2"
                    >
                      <i className="fa-regular fa-arrow-down mr-2" /> Download Excel
                    </Button>
                    <Dropdown.Button
                      // onClick={e => this.export.showModal()}
                      onClick={e => this.get_party_ledger_pdf()}
                      menu={{
                        items: ActionItems,
                        style: {
                          width: '150px',
                        },
                      }}
                      icon={<i className="fa-sharp fa-solid fa-chevron-down"></i>}
                      type="action-3"
                    >
                      <i className="fa-solid fa-file-pdf mr-2"></i>
                      {/* <i className="fa-regular fa-arrow-down mr-2" />  */}
                      View PDF
                    </Dropdown.Button>
                  </div>
                </div>
              </div>

              <PartyLedgerOptions
                ref={this.selectDateRef}
                selectedUser={this.state.selectedUser}
                type={this.props?.type}
                callData={this.callData}
                changePendingDocs={this.changePendingDocs}
                changeDates={this.changeDates}
                changeShareVisible={this.changeShareVisible}
                changeCustomDateRange={this.changeCustomDateRange}
              />
              <Modal
                title={<span>Share Ledger</span>}
                visible={this.state.shareVisible}
                footer={null}
                onCancel={() => this.setState({ shareVisible: false })}
                onClose={() => this.setState({ shareVisible: false })}
                width={380}
                closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
                centered
              >
                <div className="flex flex-col">
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <div
                      onClick={event => {
                        event.stopPropagation()
                        window.open(
                          'https://api.whatsapp.com/send/?phone=' +
                            selectedUser.dial_code +
                            selectedUser.phone +
                            '&text=Hello%20*' +
                            selectedUser.name +
                            '*%2C%20%0A%0APlease%20find%20your%20transactions%20here%2C%20%0A' +
                            config.url.short_URL +
                            '/view/ledger/' +
                            selectedUser.type +
                            '/' +
                            selectedUser.hash_id +
                            '%0A%0AThanks%0A*' +
                            store.get('company_name') +
                            '*%0A*' +
                            store.get('company_mobile') +
                            '*' +
                            (store.get('paid') == 0
                              ? '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)'
                              : ''),
                        )
                      }}
                    >
                      <div className="flex justify-between items-center my-3">
                        <div>
                          <span>
                            <i
                              className="fa-brands fa-whatsapp align-middle  font-size-28 mr-2"
                              style={{
                                color: '#25D366',
                              }}
                            />
                            <span className="font-size-18 align-middle font-weight-bold">
                              Share on Whatsapp
                            </span>
                          </span>
                        </div>

                        <span>
                          <i className="fa-solid fa-chevron-right text-gray font-size-18"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <CopyToClipboard
                    text={
                      config.url.short_URL +
                      '/view/ledger/' +
                      this.state.selectedUser.type +
                      '/' +
                      this.state.selectedUser.hash_id
                    }
                    onCopy={() => message.success('Copied')}
                  >
                    <div
                      className="flex justify-between items-center flex-nowrap my-3 border rounded px-2 py-3"
                      style={{
                        cursor: 'pointer',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <div>
                        <span>
                          <span className="font-size-14 align-middle font-weight-bold text-truncate">
                            {config.url.short_URL +
                              '/view/ledger/' +
                              this.state.selectedUser.type +
                              '/' +
                              this.state.selectedUser.hash_id}
                          </span>
                        </span>
                      </div>
                      <span>
                        <i className="fa-solid fa-copy text-gray-10 font-size-18"></i>
                      </span>
                    </div>
                  </CopyToClipboard>
                </div>
              </Modal>
            </div>
          )}

          <div
            className="mb-1 mt-4 pt-4 border-t-gray-100"
            style={{ maxHeight: '400px', overflowY: 'auto' }}
          >
            <div className="float-left">
              <RangePicker
                allowClear={false}
                ranges={
                  this.props?.country_info?.[this.props?.user?.selectedCompany?.country_code]
                    ?.date_ranges
                }
                onChange={(dates, dateStrings) =>
                  this.setState(
                    {
                      dates: dateStrings[0] + ' - ' + dateStrings[1],
                      loading: true,
                      page: 0,
                    },
                    () => this.callData(this.state.page, this.state.num_records),
                  )
                }
                format={this.dateFormat}
                defaultValue={
                  this.state.dates != ''
                    ? [
                        dayjs(this.state.dates.split(' - ')[0], this.dateFormat),
                        dayjs(this.state.dates.split(' - ')[1], this.dateFormat),
                      ]
                    : ''
                }
              />{' '}
              <ShowDateString dateString={this.state.dates} showInputWidth={false} />
            </div>
            {this.props.type == 'customer' && (
              <div className="float-right">
                <Tooltip
                  title={
                    'Pending ' +
                    (this.props.type == 'customer' ? 'Invoices' : 'Purchases') +
                    ' will be shown irrespective of payments received/paid.'
                  }
                >
                  <Checkbox
                    onChange={e => {
                      this.setState({ show_pending_docs: e.target.checked }, () =>
                        this.callData(0, this.state.num_records),
                      )
                    }}
                    checked={this.state.show_pending_docs}
                    className="text-gray-600 mt-4"
                  >
                    Show Pending {this.props.type == 'customer' ? 'Invoices' : 'Purchases'}
                  </Checkbox>
                </Tooltip>
              </div>
            )}
            <Table
              onRow={(record, rowIndex) => {
                return {
                  className: 'cursor-pointer' + (record.type == '' ? ' bg-gray-50' : ''),
                  onClick: event => {
                    if (
                      record.type !== 'Balance' &&
                      record.type !== '' &&
                      record.type !== 'Expense'
                    ) {
                      if (record.document_type == 'payment') {
                        const data = { ...record }
                        this.edit.onAdd(
                          this.props.utilts,
                          data,
                          this.getMenuName(record.document_type),
                        )
                      } else {
                        this.invoiceForm.showDrawer(this.getMenuName(record.document_type), {
                          ...record,
                          new_hash_id: record.hash_id,
                        })
                      }
                    }
                  },
                }
              }}
              columns={columns}
              dataSource={this.state.data}
              pagination={false}
              style={{ minHeight: '300px' }}
              size="small"
              onChange={this.handleTableChange}
              loading={{
                spinning: this.state.loading,
                indicator: <Loader text={'Loading...'} visibleText={false} />,
              }}
            />
          </div>
          <Affix offsetBottom={0} target={() => window}>
            <div>
              <div className="row border-t-gray-100"></div>
              <div className="flex items-center pt-3 pb-5 bg-white">
                <div className="mr-auto">
                  {this.props.permissions.record_payment == 1 && (
                    <Button
                      type="success-3"
                      size=""
                      onClick={() => {
                        this.payin.onAdd(this.props.utilts, selectedUser, true)
                      }}
                      className="font-weight-bold px-4 mr-2"
                      loading={this.state.loading}
                    >
                      <i className="fa fa-arrow-down mr-1" />{' '}
                      <FormattedMessage id="button.youGot" />
                    </Button>
                  )}
                  {this.props.permissions.record_payment == 1 && (
                    <Button
                      type="danger-3"
                      size=""
                      onClick={() => {
                        this.payin.onAdd(this.props.utilts, selectedUser, false)
                      }}
                      className="px-4 font-weight-bold"
                      loading={this.state.loading}
                    >
                      <i className="fa fa-arrow-up mr-1" /> <FormattedMessage id="button.youGave" />
                    </Button>
                  )}
                </div>

                <div className="float-right">
                  {this.state.data.length != 0 && (
                    <div className="inline-flex items-center">
                      <Pagination
                        size="small"
                        showSizeChanger={false}
                        pageSize={this.state.num_records}
                        total={this.state.total}
                        onChange={this.onPaginationChange}
                      />
                      <Select
                        optionFilterProp="children"
                        className="mr-2"
                        defaultValue={this.state.num_records}
                        onChange={value => this.callData(this.state.page, value)}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {[5, 10, 20, 50, 100].map((e, i) => (
                          <Option key={i} value={e}>
                            {e}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Affix>

          {this.state.invoiceData != '' && (
            <Modal
              closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
              open={this.state.invoiceFlag}
              style={{ top: 9 }}
              width={900}
              bodyStyle={{ height: '100vh' }}
              onOk={() => this.setState({ invoiceFlag: false })}
              onCancel={() => this.setCancelledModal()}
              footer={null}
            >
              <h4 className="font-weight-bolder font-size-18 mb-4">
                <span className="text-gray-10">{this.state.document_title} Preview</span>
              </h4>
              <Checkbox.Group
                onChange={e => {
                  this.setState({
                    type: [...e],
                    myKey: Math.random(),
                  })
                }}
                options={this.state.optionCheckbox}
                defaultValue={this.state.type}
                className="mb-3"
              ></Checkbox.Group>

              <Button
                type="whatsapp"
                size="small"
                className="mr-2 float-right font-weight-bold"
                style={{ height: '24px', borderRadius: '8px' }}
                onClick={event => {
                  event.stopPropagation()
                  window.open(
                    'https://wa.me/send/?' +
                      (this.state.invoiceData.customer.phone != null &&
                      this.state.invoiceData.customer.phone.length == 10
                        ? 'phone=91' + this.state.invoiceData.customer.phone
                        : '') +
                      '&text=Hello' +
                      (this.state.invoiceData.customer.name != ''
                        ? '%20*' + encodeURIComponent(this.state.invoiceData.customer.name) + '*'
                        : '') +
                      '%2C%20%0A%0A' +
                      encodeURIComponent(this.state.invoiceData.company.whatsapp_line1) +
                      '%20%0A%0APlease%20find%20your%20invoice%20' +
                      this.state.invoiceData.serial_number +
                      '%20here%2C%20%0Ahttps://swipe.pe/n/view/' +
                      'sales' +
                      '/' +
                      this.state.invoiceData.new_hash_id +
                      '%0A%0A' +
                      encodeURIComponent(this.state.invoiceData.company.whatsapp_line2) +
                      '%0A%0AThanks%0A*' +
                      encodeURIComponent(this.state.invoiceData.company.organization_name) +
                      '*%0A*' +
                      this.state.invoiceData.company.mobile +
                      '*' +
                      (store.get('paid') == 0
                        ? '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)'
                        : ''),
                  )
                }}
              >
                <i className="fa fa-whatsapp fa-lg mr-1" /> Whatsapp
              </Button>

              <Tooltip title="Email">
                <Button
                  type="email"
                  size="small"
                  style={{ height: '24px', borderRadius: '8px' }}
                  className="hidden sm:inline mr-2 float-right"
                  target="_blank"
                  onClick={event => {
                    this.mail.onShow(this.state.invoiceData)
                  }}
                >
                  <i className="fa fa-envelope-o mr-1" /> Email
                </Button>
              </Tooltip>

              <MyDocument
                data={this.state.invoiceData}
                menuName={this.getMenuName(this.state.document_type)}
                type={this.state.type}
                myKey={this.state.myKey}
                flag={this.state.challanFlag}
              />
            </Modal>
          )}
          <EnhancedCustomerFrom
            onRef={ref => (this.customer = ref)}
            onAPICalled={this.onAPICalled}
          />
          <EnhancedVendorFrom onRef={ref => (this.vendor = ref)} onAPICalled={this.onAPICalled} />
          <EnhancedExport
            onRef={ref => (this.export = ref)}
            menuName={this.props.type == 'customer' ? 'customer/ledger' : 'vendor/ledger'}
            id={this.state.customer_id}
          />
          {/* <EnhancedExportPDF
              onRef={ref => (this.exportPDF = ref)}
              menuName={`${this.props.type}/ledger`}
              id={this.state.selectedUser.id}
              onAPICalled={this.ledgerPDFView}
            /> */}
          <EditPayInForm
            onRef={ref => (this.edit = ref)}
            onApicalled={() => this.callData(this.state.page, this.state.num_records)}
          />
          <PayInForm
            onRef={ref => (this.payin = ref)}
            onApicalled={() => this.callData(this.state.page, this.state.num_records)}
          />
          <EnhancedDelete
            onRef={ref => (this.delete = ref)}
            document_type={'payment'}
            handleDelete={this.handleDelete}
          />
          <EnhancedDelete
            onRef={ref => (this.document_cancel = ref)}
            document_type={this.state.document_type}
            handleDelete={this.handle_document_cancel}
          />
          <EnhancedMailFrom
            onRef={ref => (this.mail = ref)}
            document_type={this.state.document_type}
          />
          {this.state.visible && (
            <EnhancedCreate
              onRef={ref => (this.create = ref)}
              documentType={this.state.document_type}
              customerId={selectedUser.id}
              onClose={() => this.setState({ visible: false })}
            />
          )}
        </Card>
        <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} callData={this.callData} />
        <Modal
          open={this.state.show_ledger_pdf}
          onCancel={() => this.setState({ show_ledger_pdf: false })}
          title="Party Ledger"
          style={{ top: 20 }}
          width={900}
          onOk={() => this.setState({ show_ledger_pdf: false })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          footer={[
            <Button
              key="back"
              type="default"
              onClick={() => this.setState({ show_ledger_pdf: false })}
            >
              Cancel
            </Button>,
          ]}
        >
          <Button
            type="send-button"
            size="small"
            className="mr-2 mb-3 float-right font-weight-bold"
            style={{ height: '24px', borderRadius: '8px' }}
            onClick={() => {
              this.selectDateRef?.current?.openShareModal(
                this.state.show_pending_docs,
                this.state?.num_records,
                this.state?.page,
                this.state?.showCustomDateRange,
              )
            }}
          >
            <i className="fa-solid fa-paper-plane fa-fw mr-2" />
            Share{' '}
          </Button>

          {/* <PDFViewer key={Math.random()} className="col-12" style={{ height: '900px' }}> */}
          <LedgerDocument
            data={{ ...this.state.pdfData, show_pending_docs: this.state.show_pending_docs }}
          />
          {/* </PDFViewer> */}
        </Modal>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    utilts: {
      payment_modes: state.document.paymentModes,
      bank_details: state.document.bankDetails,
    },
    user: state.user,
    country_info: state.countries.info,
  }
}

export default connect(mapStateToProps)(Ledger)

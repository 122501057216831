import { default as EnhancedFrom, default as ProductForm } from 'components/forms/productForm'
import CopyText from 'components/other/CopyText'
// import ProductAnalysis from 'components/other/productAnalysis'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Modal,
  Card,
  Collapse,
  DatePicker,
  Avatar,
  Divider,
  Empty,
  Popover,
  Select,
  Spin,
  Table,
  Tabs,
  Timeline,
  Tooltip,
} from 'antd'
import { Option } from 'antd/lib/mentions'
import AutoFinish from 'components/autoFinish'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import ProductImagesCarousel from 'components/modal/productAnalysis/productImagesCarousel'
import dayjs from 'dayjs'
// import { TabPane } from 'react-bootstrap'
import { downloadAPI, getAPI, getAPIData } from 'services/jwt'
import queryString from 'query-string'
import ProductPriceList from 'components/PriceList'
import BetaBadge from 'components/badges/beta'
import { debounce } from 'lodash'
import AuditHistory from 'components/other/Activity'
const { Panel } = Collapse
const { TabPane } = Tabs
const { RangePicker } = DatePicker
class EnhancedProductAnalysis extends Component {
  form = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      table_loading: false,
      inventory: {
        num_records: 5,
        page: 0,
        pagination: {
          current: 1,
          pageSize: 5,
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
        },
        columns: [
          {
            title: <FormattedMessage id="table.item" />,
            dataIndex: 'product_name',
            render: (text, data) => (
              <span>
                <span className="font-weight-bold">
                  {text} {data.variant_name}
                  <p className="font-size-12 text-gray-500">{data.batch_no}</p>
                </span>
                {data.product_category && data.product_category != '' && (
                  <span className="font-weight-bold font-size-10">
                    <p className="mt-0 mb-0">
                      {/*                  <span className="text-purple mr-1">{data.product_type}</span>
                       */}{' '}
                      <span className="text-orangeproduct mr-1">
                        {data.product_category.length > 25
                          ? data.product_category.substring(0, 20) + '...'
                          : data.product_category}
                      </span>
                    </p>
                  </span>
                )}
              </span>
            ),
          },
          Table.EXPAND_COLUMN,
          {
            title: <FormattedMessage id="table.stockIn" />,
            dataIndex: 'qty',
            render: (text, data) => {
              return {
                props: {
                  className: 'bg-positive',
                },
                children: (
                  <>
                    {data.stock_option == 'in' && (
                      <span className="font-weight-bolder text-forest">
                        {text}
                        {data.unit != 'OTH' && (
                          <span className="font-size-12 font-weight-bold text-purple ml-2">
                            {data.unit}
                          </span>
                        )}
                      </span>
                    )}
                  </>
                ),
              }
            },
          },

          {
            title: <FormattedMessage id="table.stockOut" />,
            dataIndex: 'qty',
            render: (text, data) => {
              return {
                props: {
                  className: 'bg-negative',
                },
                children: (
                  <>
                    {data.stock_option == 'out' && (
                      <span className="font-weight-bolder text-danger">
                        {text}
                        {data.unit != 'OTH' && (
                          <span className="font-size-12 font-weight-bold text-purple ml-2">
                            {data.unit}
                          </span>
                        )}
                      </span>
                    )}
                  </>
                ),
              }
            },
          },
          {
            title: <FormattedMessage id="table.source" />,
            dataIndex: 'document_type',
            responsive: ['md'],
            render: (text, data) =>
              data.bom_id == 0 ? (
                <span
                  className="text-purple font-weight-bold font-size-12 cursor-pointer"
                  onClick={() => this.showDocumentDrawer(data.document_type, data.new_hash_id)}
                >
                  <p className="mt-0 mb-0 text-uppercase">{data.document_type}</p>
                  <p className="mt-0 mb-0 text-gray-700">{data.serial_number}</p>
                </span>
              ) : (
                <span>
                  <p className="mt-0 mb-0 text-uppercase">Raw Material</p>
                </span>
              ),
          },
          {
            title: <FormattedMessage id="table.remarks" />,
            dataIndex: 'remarks',
            responsive: ['md'],
            render: (text, data) => (
              <span>
                <span className="font-size-13">{data.remarks}</span>
                {data.transfer_id != -1 && (
                  <>
                    <span className="font-size-13">
                      <br />
                      Transfered from{' '}
                      <span className="font-weight-bold">{data.from_warehouse}</span> to{' '}
                      <span className="font-weight-bold">{data.to_warehouse}</span>
                    </span>
                  </>
                )}
              </span>
            ),
          },
          {
            title: (
              <>
                <span>
                  <span className="font-size-12 mt-0 mb-0">
                    <FormattedMessage id="table.date" />{' '}
                  </span>
                  <span className="font-size-10 mb-0 mt-0">/ Updated By</span>
                </span>
              </>
            ),
            dataIndex: 'transaction_date',
            render: (text, data) => (
              <span>
                <span className="font-size-13">{text}</span>
                <span className="text-purple font-weight-bold hidden sm:inline font-size-10">
                  <p className="mt-0 mb-0">by {data.name}</p>
                </span>
              </span>
            ),
          },
        ].filter(item => !item.hidden),
      },
      party: {
        num_records: 5,
        page: 0,
        pagination: {
          current: 1,
          pageSize: 5,
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
        },
      },
      transactions: {
        num_records: 5,
        page: 0,
        pagination: {
          current: 1,
          pageSize: 5,
          defaultPageSize: 5,
          pageSizeOptions: ['5', '10', '20', '50', '100'],
        },
        data: [],
        columns: [
          'serial_number',
          'party_name',
          'document_date',
          'unit_price',
          'price_with_tax',
          'qty',
          'discount',
          'net_amount',
          'total_amount',
        ],
      },
      tab: '1',
      visualization: '1',
      start_date: dayjs()
        .startOf('year')
        .format('DD-MM-YYYY'),
      end_date: dayjs()
        .endOf('year')
        .format('DD-MM-YYYY'),
      request_type: 'Bill-Wise Transactions',
      download: 0,
      tabs: ['Bill-Wise Transactions', 'Party Transactions', 'Inventory Timeline'],
      party_transactions: [
        {
          title: 'Customers',
          party_type: 'customer',
        },
        {
          title: 'Vendors',
          party_type: 'vendor',
        },
      ],
      menu_name: props.selectedParty?.menu_name || 'sales',
      doc_transactions: [
        {
          title: 'Sales',
          document_type: 'invoice',
          menu_name: 'sales',
          party_type: 'customer',
        },
        {
          title: 'Sales Returns',
          document_type: 'sales_return',
          menu_name: 'sales_returns',
          party_type: 'customer',
        },
        {
          title: 'Estimates',
          document_type: 'estimate',
          menu_name: 'estimates',
          party_type: 'customer',
        },
        {
          title: 'Purchases',
          document_type: 'purchase',
          menu_name: 'purchases',
          party_type: 'vendor',
        },
        {
          title: 'Purchase Returns',
          document_type: 'purchase_return',
          menu_name: 'purchase_returns',
          party_type: 'vendor',
        },
        {
          title: 'Purchase Orders',
          document_type: 'purchase_order',
          menu_name: 'purchase_orders',
          party_type: 'vendor',
        },
        {
          title: 'Delivery Challans',
          document_type: 'delivery_challan',
          menu_name: 'delivery_challans',
          party_type: 'customer',
        },
        {
          title: 'Pro Forma Invoices',
          document_type: 'pro_forma_invoice',
          menu_name: 'pro_forma_invoices',
          party_type: 'customer',
        },
      ],
      columns: [],
      dataSource: [],
      sorter: {},
      sort_type: '',
      search: props.selectedParty?.name || '',
      party_id: props.selectedParty?.id || -1,
      party_type: 'customer',
      product_audit_history: [],
      flag: false,
      dataOnload: false,
      product_updated: false,
      showVariantDropDown: false,
      selectedVariant: {},
      product_analytics: {},
      data: {},
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = async (data, product_id, selectedVariant = {}, partyData = {}) => {
    if (!(selectedVariant?.variant_id && selectedVariant?.variant_id !== 0)) {
      selectedVariant = {}
    } else {
      selectedVariant = {
        ...selectedVariant,
        id: selectedVariant?.variant_id,
        name: selectedVariant?.variant_name || selectedVariant?.name,
      }
    }
    const analysis = await getAPI('v2/product', 'analysis', {
      product_id: product_id,
      variant_id: selectedVariant?.variant_id,
      party: {
        page: 0,
        num_records: 5,
      },
      inventory: {
        page: 0,
        num_records: 5,
      },
      transactions: {
        page: 0,
        num_records: 5,
      },
      start_date: '',
      end_date: '',
      request_type: 'Inventory',
      transaction_type: 'All',
    })

    if (!analysis || !analysis.success) {
      return
    }
    this.setState(
      {
        product_id: product_id,
        data: { ...data, variants: analysis.product_details.variants },
        selectedVariant: selectedVariant,
        modalTitle:
          data.product_name +
            (selectedVariant?.variant_name
              ? ' - ' + selectedVariant?.variant_name
              : selectedVariant?.name
              ? ' - ' + selectedVariant?.name
              : '') || '',
        loading: true,
        dataOnload: true,
        flag: true,
        selectedParty: partyData,
        product_analytics: analysis.product_details,
      },
      () => {
        this.callData()
      },
    )
  }

  handleCancel = () => {
    this.setState({ flag: false, loading: false, dataOnload: false }, () => {
      if (this.state.product_updated) {
        this.setState({ product_updated: false })
        this.props.onProductUpdate(this.state.data)
      }
    })
  }

  productDetailsColumns = (label, value) => {
    return (
      <div className="mt-2 mb-3">
        <div className="text-gray-500 font-size-16 font-weight-medium">{label}</div>
        <div className="font-size-bold font-size-24 font-weight-bold mt-1">{value}</div>
      </div>
    )
  }

  changeCustomerPrice = (newPriceValue, priceType) => {
    if (this.props.onChangeCustomerPrice) {
      this.setState({ flag: false }, () => {
        this.props.onChangeCustomerPrice(newPriceValue, this.state.data, priceType)
      })
    } else {
      console.log('')
    }
  }

  callData = async () => {
    this.setState({ table_loading: true })

    let party_id = this.state.party_id

    if (this.state.request_type === 'Bill-Wise Transactions') {
      const req = {
        date_range: `${this.state.start_date} - ${this.state.end_date}`,
        download: this.state.download,
        menu_name: this.state.menu_name || 'sales',
        num_records: this.state.transactions.num_records,
        page: this.state.transactions.page,
        party_id: party_id || -1,
        product_id: this.state.product_id,
        sorter: this.state.sorter,
        sort_type: this.state.sort_type,
        variant_name: this.state.selectedVariant?.name || '',
      }
      const data = await downloadAPI('v2/reports', 'transaction_wise_items', req)
      if (data && data.success) {
        this.setState({
          transactions: {
            ...this.state.transactions,
            data: data.items,
            pagination: {
              ...this.state.transactions.pagination,
              current: this.state.transactions.page + 1,
              pageSize: this.state.transactions.num_records,
              total: data.total_records,
            },
          },
          columns: data.columns
            .filter(col => this.state.transactions.columns.includes(col.dataIndex))
            .sort(
              (a, b) =>
                this.state.transactions.columns.indexOf(a.dataIndex) -
                this.state.transactions.columns.indexOf(b.dataIndex),
            )
            .map((item, index) => {
              return {
                title: item['title'],
                dataIndex: item['dataIndex'],
                key: item['dataIndex'],
                render: (text, record) => {
                  if (item.dataIndex == 'unit_price' || item.dataIndex == 'price_with_tax') {
                    return (
                      <div>
                        <Tooltip
                          title={this.props.onChangeCustomerPrice ? 'Click to use this price' : ''}
                        >
                          <span
                            onClick={e => {
                              e.stopPropagation()
                              this.changeCustomerPrice(
                                text,
                                item.dataIndex == 'unit_price' ? 'price' : 'price_with_tax',
                              )
                            }}
                            className={this.props.onChangeCustomerPrice ? 'hover:underline' : ''}
                          >
                            {text}
                          </span>
                        </Tooltip>
                      </div>
                    )
                  }
                  return (
                    <div>
                      <span>{text}</span>
                    </div>
                  )
                },
              }
            }),
          dataSource: data.items,
          download: 0,
        })
      }
    } else if (this.state.request_type === 'Inventory Timeline') {
      const req = {
        date: `${this.state.start_date} - ${this.state.end_date}`,
        date_range: `${this.state.start_date} - ${this.state.end_date}`,
        num_records: this.state.inventory.num_records,
        page: this.state.inventory.page,
        search: this.state.data.product_name,
        search_type: 'Product Name',
        product_id: this.state.product_id,
        variant_id: this.state.selectedVariant?.variant_id || 0,
        batch_id: 0,
        warehouse_id: -1,
        download: this.state.download,
      }
      const data = await downloadAPI('v2/reports', 'stock_report', req)

      if (data && data.success) {
        this.setState({
          inventory: {
            ...this.state.inventory,
            data: data.transactions,
            pagination: {
              ...this.state.inventory.pagination,
              current: this.state.inventory.page + 1,
              pageSize: this.state.inventory.num_records,
              total: data.tr[0]?.count,
            },
          },
          columns: this.state.inventory.columns,
          dataSource: data.transactions,
          download: 0,
        })
      }
    } else if (this.state.request_type === 'Party Transactions') {
      const req = {
        product_id: this.state.product_id,
        variant_id: this.state.selectedVariant?.variant_id || 0,
        party_id: party_id || -1,
        party_type: this.state.party_type,
        page: this.state.party.page,
        num_records: this.state.party.num_records,
        date: `${this.state.start_date} - ${this.state.end_date}`,
        request_type: this.state.request_type,
        transaction_type: this.state.party_type,
        download: this.state.download,
        product_name: this.state.data.product_name,
        variant_name: this.state.selectedVariant?.name || '',
      }

      const data = await downloadAPI('v2/product', 'party_wise_transactions', req)
      if (data) {
        if (data.success) {
          this.setState({
            dataSource: data.transactions,
            columns: data.tr_columns.map((item, index) => {
              return {
                title: item['title'],
                dataIndex: item['key'],
                key: item['key'],
                render: (text, record) => {
                  return (
                    <div>
                      <span>{text}</span>
                    </div>
                  )
                },
              }
            }),
            party: {
              ...this.state.party,
              pagination: {
                ...this.state.party.pagination,
                current: this.state.party.page + 1,
                pageSize: this.state.party.num_records,
                total: data.transactions_count,
              },
            },
            download: 0,
          })
        }
      }
    } else if (this.state.request_type === 'audit_history') {
      const req = {
        product_id: this.state.product_id,
        variant_id: this.state.selectedVariant?.variant_id || 0,
      }
      const data = await getAPIData('product', 'get_history?' + queryString.stringify(req))
      if (data.success) {
        this.setState({
          product_audit_history: data.audit_history,
        })
      }
    }

    // this.props.dataOnload(true)
    this.setState({
      loading: false,
      table_loading: false,
      download: 0,
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const page = pagination.current - 1
    const num_records = pagination.pageSize

    if (!('order' in sorter && sorter.order)) {
      sorter = {
        order: 'ascend',
        field: '',
      }
    }

    if (this.state.request_type === 'Bill-Wise Transactions') {
      this.setState(
        {
          transactions: {
            ...this.state.transactions,
            num_records: num_records,
            page: page,
            pagination: pagination,
          },
          sorter: sorter.order == 'ascend' ? 'asc' : 'desc',
          sort_type: sorter.field || '',
        },
        () => {
          this.callData()
        },
      )
    } else if (this.state.request_type === 'Inventory Timeline') {
      this.setState(
        {
          inventory: {
            ...this.state.inventory,
            num_records: num_records,
            page: page,
            pagination: pagination,
            sorter: sorter,
          },
          sorter: sorter.order == 'ascend' ? 'asc' : 'desc',
          sort_type: sorter.field || '',
        },
        () => {
          this.callData()
        },
      )
    } else {
      this.setState(
        {
          party: {
            ...this.state.party,
            num_records: num_records,
            page: page,
            pagination: pagination,
            sorter: sorter,
          },
          sorter: sorter.order == 'ascend' ? 'asc' : 'desc',
          sort_type: sorter.field || '',
        },
        () => {
          this.callData()
        },
      )
    }
  }

  handleTabChange(key) {
    this.setState(
      {
        request_type: key,
        download: 0,
        party_type: this.state.doc_transactions.filter(
          (type, index) => type.menu_name == this.state.menu_name,
        )[0].party_type,
        search: this.state.search,
      },
      () => this.callData(),
    )
  }

  render() {
    const { request_type } = this.state

    let pagination = ''
    if (request_type == 'Bill-Wise Transactions') {
      pagination = this.state.transactions.pagination
    } else if (request_type == 'Inventory Timeline') {
      pagination = this.state.inventory.pagination
    } else {
      pagination = this.state.party.pagination
    }
    return (
      <>
        <Modal
          title={
            <>
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="mb-0">
                    {' '}
                    <CopyToClipboard
                      text={this.state.modalTitle}
                      onCopy={() => {
                        message.success('Copied Product Name')
                      }}
                    >
                      <>
                        <CopyText label="Product Name" text={this.state.data?.product_name} />
                        {this.state.data?.variants && this.state.data?.variants.length > 0 && (
                          <span className="select-borderless">
                            {' - '}
                            <Select
                              size={'large'}
                              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                              placeholder={'Select Variant'}
                              optionFilterProp="children"
                              style={{ maxWidth: 200 }}
                              listHeight={200}
                              dropdownStyle={{ maxWidth: '360px', minWidth: '180px' }}
                              onChange={e => {
                                this.showModal(
                                  {
                                    ...this.state.data,
                                    new_variant_id: e,
                                    variant_id: e,
                                  },
                                  this.state.product_id,
                                  {
                                    ...this.state.data?.variants?.find(
                                      item => item.new_variant_id === e,
                                    ),
                                    variant_id: e,
                                    new_variant_id: e,
                                  },
                                )
                                this.setState({
                                  showVariantDropDown: false,
                                })
                              }}
                              showSearch
                              value={this.state.selectedVariant?.variant_id}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {this.state.data?.variants?.map((item, i) => {
                                return (
                                  <Option
                                    key={i}
                                    value={item.new_variant_id}
                                    className="font-weight-bold"
                                  >
                                    {item.name}
                                  </Option>
                                )
                              })}
                            </Select>
                          </span>
                        )}
                      </>
                    </CopyToClipboard>
                  </h4>
                </div>
                <div>
                  <Button
                    type="warning"
                    size="default"
                    onClick={event => {
                      event.stopPropagation()
                      this.form.onFill(this.state.data)
                    }}
                    className="mr-4"
                  >
                    <i className="fa-solid fa-edit mr-1" />
                    <span className="hidden sm:inline font-weight-bold">
                      <FormattedMessage id="button.edit" />
                    </span>
                  </Button>
                </div>
              </div>
            </>
          }
          open={this.state.flag}
          onCancel={this.handleCancel}
          closeIcon={<i className="fa-solid  fa-xmark"></i>}
          wrapStyle={{ display: 'block', backdropFilter: 'blur(3px)' }}
          mask={true}
          width={'80%'}
          styles={{
            body: { height: '80vh' },
          }}
          destroyOnClose
          footer={[]}
          centered
        >
          <div className="">
            {' '}
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
              tip="Loading Product Analytics..."
              spinning={this.state.loading}
            >
              <div className="mb-2">
                <div className="flex flex-wrap ml-1">
                  <div className="w-1/4">
                    {this.productDetailsColumns(
                      <>
                        Price
                        <br />
                        {this.state.product_analytics['is_price_with_tax'] == 1 ? (
                          <p className="font-size-10 mb-0 mt-0">
                            {this.state.product_analytics['Tax %%'] != 0 && (
                              <>
                                <span className="font-weight-bold">
                                  {this.state.product_analytics['Tax %%']}%{' '}
                                </span>
                                <FormattedMessage id="text.withTax" />
                              </>
                            )}
                          </p>
                        ) : (
                          <p className="font-size-10 mb-0 mt-0">
                            {this.state.product_analytics['Tax %%'] != 0 && (
                              <>
                                <span className="font-weight-bold">
                                  {this.state.product_analytics['Tax %%']}%{' '}
                                </span>
                                <FormattedMessage id="text.withoutTax" />
                              </>
                            )}
                          </p>
                        )}
                      </>,
                      <div className="text-forest">
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.state.product_analytics['Price']}
                      </div>,
                    )}
                  </div>

                  <div className="w-1/4">
                    {this.productDetailsColumns(
                      'Sale Amount',
                      <div className="text-forest">
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.state.product_analytics['Sale Without Tax']}
                      </div>,
                    )}
                  </div>
                  <div className="w-1/4">
                    {this.productDetailsColumns(
                      'Total Discount',
                      <div className="text-danger">
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.state.product_analytics['Total Discount']}
                      </div>,
                    )}
                  </div>

                  <div className="w-1/4">
                    {this.productDetailsColumns(
                      'Current Stock',
                      <div className="">{this.state.product_analytics['Qty']}</div>,
                    )}
                  </div>
                  <div className="w-1/2">
                    {this.productDetailsColumns(
                      'Total Qty In & Out',
                      this.state.product_analytics['Total Qty'],
                    )}
                  </div>
                  {this.state.data?.product_images?.length > 0 && (
                    <div className="w-1/2">
                      <div className="mb-1 text-gray-500 font-size-16 font-weight-medium">
                        {'Images'}
                      </div>
                      <div
                        className="flex"
                        onClick={() =>
                          this.productImagesRef.onFillImages(this.state.data?.product_images)
                        }
                      >
                        <Avatar.Group
                          maxCount={2}
                          maxStyle={{ color: '#fc3654', backgroundColor: '#fde3cf' }}
                          className="float-right align-middle ml-3"
                        >
                          {this.state.data?.product_images?.map((item, index) => (
                            <Avatar shape="circle" size="default" src={item.url} />
                          ))}
                        </Avatar.Group>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <Tabs
                    defaultActiveKey="1"
                    onChange={key => this.handleTabChange(key)}
                    className="mt-2"
                  >
                    {this.state.tabs.map((tab, index) => (
                      <TabPane tab={tab} key={tab}>
                        <div className="mt-0">
                          <div className="flex flex-row row mx-1">
                            <RangePicker
                              allowClear={false}
                              ranges={
                                this.props?.country_info?.[
                                  this.props?.user?.selectedCompany?.country_code
                                ]?.date_ranges
                              }
                              className="mb-3 mr-2"
                              disabled={this.state.loading}
                              onChange={(date, dateStrings) => {
                                this.setState(
                                  {
                                    start_date: dateStrings[0],
                                    end_date: dateStrings[1],
                                  },
                                  () => this.callData(),
                                )
                              }}
                              defaultValue={[dayjs().startOf('year'), dayjs().endOf('year')]}
                              style={{
                                width: '30%',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                              format={this.dateFormat}
                            />
                            {tab == 'Bill-Wise Transactions' && (
                              <Select
                                showSearch
                                placeholder="Select Transaction Type"
                                className="mb-3 mr-2"
                                disabled={this.state.loading}
                                value={this.state.menu_name}
                                onChange={value => {
                                  let doc_type = this.state.doc_transactions.filter(
                                    (type, index) => type.menu_name == value,
                                  )[0]
                                  this.setState(
                                    {
                                      menu_name: value,
                                      party_type: doc_type?.party_type,
                                      party_id: -1,
                                      search: '',
                                    },
                                    () => {
                                      this.callData()
                                    },
                                  )
                                }}
                                style={{
                                  width: '20%',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                {this.state.doc_transactions.map((type, index) => (
                                  <Option key={type.document_type} value={type.menu_name}>
                                    {type.title}
                                  </Option>
                                ))}
                              </Select>
                            )}
                            {tab == 'Party Transactions' && (
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select Transaction Type"
                                className="mb-3 mr-2"
                                disabled={this.state.loading}
                                value={this.state.party_type}
                                onChange={value => {
                                  this.setState(
                                    { party_type: value, party_id: -1, search: '' },
                                    () => {
                                      this.callData()
                                    },
                                  )
                                }}
                                style={{
                                  width: '20%',
                                  borderRadius: '5px',
                                  cursor: 'pointer',
                                }}
                              >
                                {this.state.party_transactions.map((type, index) => (
                                  <Option key={type.party_type} value={type.party_type}>
                                    {type.title}
                                  </Option>
                                ))}
                              </Select>
                            )}
                            {(tab == 'Party Transactions' || tab == 'Bill-Wise Transactions') && (
                              <AutoFinish
                                style={{ width: '30%' }}
                                placeholder={'Search ' + this.state.party_type ?? customer}
                                autoFocus={true}
                                searchType={this.state.party_type ?? 'customer'}
                                onRef={ref => (this.autoFinish = ref)}
                                callData={(value, option) => {
                                  this.setState({ party_id: option.id, search: option.name }, () =>
                                    this.callData(),
                                  )
                                }}
                                dropdownMatchSelectWidth={true}
                                onChange={value => this.setState({ search: value })}
                                searchText={this.state.search}
                                allowClear
                                onClear={() => {
                                  this.setState({ party_id: -1, search: '' }, () => this.callData())
                                }}
                              />
                            )}

                            <div className=" ml-auto flex-inline flex-col">
                              <>
                                <Popover
                                  content={
                                    <>
                                      <div className="font-weight-bold">Download</div>
                                      <div className="flex flex-col">
                                        <Button
                                          type="action"
                                          className="mx-2 my-2"
                                          onClick={() =>
                                            this.setState({ download: 1 }, () => this.callData())
                                          }
                                        >
                                          <i className="fa-duotone fa-file-excel mr-2" />
                                          Download Excel
                                        </Button>
                                        <Button
                                          type="action-3"
                                          className="mx-2 my-2"
                                          onClick={() =>
                                            this.setState({ download: 2 }, () => this.callData())
                                          }
                                        >
                                          <i className="fa-duotone fa-file-pdf mr-2" />
                                          Download PDF
                                        </Button>
                                      </div>
                                    </>
                                  }
                                  trigger={['click']}
                                  placement="bottom"
                                >
                                  <Button className="  mr-2">
                                    <span className="hidden sm:inline mr-2">Download</span>
                                    <span>
                                      <i className="fa-duotone fa-down-to-line d   mt-1" />
                                    </span>
                                  </Button>
                                </Popover>
                              </>
                            </div>
                          </div>

                          {/* table Details */}
                          {this.state.visualization == '1' &&
                            (this.state.dataSource?.length > 0 ? (
                              <div
                                style={{
                                  minWidth: '100%',
                                }}
                              >
                                <Table
                                  columns={this.state.columns}
                                  dataSource={this.state.dataSource}
                                  pagination={pagination}
                                  size="small"
                                  onChange={this.handleTableChange}
                                  bordered={false}
                                  loading={this.state.table_loading}
                                  onRow={(record, rowIndex) => {
                                    return {
                                      className: 'cursor-pointer',
                                      onClick: event => {
                                        if (this.state.request_type == 'Bill-Wise Transactions') {
                                          this.invoiceForm.showDrawer(this.state.menu_name, {
                                            ...record,
                                            new_hash_id: record.hash_id,
                                          })
                                        }
                                      },
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <Empty />
                            ))}
                        </div>
                      </TabPane>
                    ))}
                    <TabPane tab={'Price List'} key={'price_list'}>
                      <ProductPriceList
                        product_id={this.state.product_id}
                        variant_id={this.props.variant_id}
                      />
                    </TabPane>
                    {this.props.permissions.history == 1 && (
                      <TabPane
                        tab={
                          <>
                            Activity
                            <BetaBadge />
                          </>
                        }
                        key={'audit_history'}
                      >
                        {this.state.table_loading ? (
                          <p className="w-full flex justify-center mt-5" style={{ minHeight: 160 }}>
                            <LoadingOutlined
                              style={{ fontSize: 40 }}
                              className="text-primary"
                              spin
                            />
                          </p>
                        ) : (
                          <AuditHistory auditHistory={this.state.product_audit_history} />
                        )}
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              </div>
            </Spin>
            <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
            <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
            <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} callData={this.callData} />
            <ProductImagesCarousel onRef={ref => (this.productImagesRef = ref)} />
          </div>
        </Modal>
        <EnhancedFrom
          onRef={ref => (this.form = ref)}
          onAPICalled={() => console.log('')}
          dispatch={this.props.dispatch}
        />
        <ProductForm
          onRef={ref => (this.form = ref)}
          onAPICalled={productData => {
            if (productData?.variants?.length == 0) {
              this.setState(
                {
                  product_updated: true,
                },
                () => {
                  this.showModal(productData, productData.product_id)
                },
              )
            }
          }}
          dispatch={this.props.dispatch}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.permissions.permission,
    user: state.user,
    country_info: state.countries.info,
  }
}

export default connect(mapStateToProps)(EnhancedProductAnalysis)

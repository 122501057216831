import React, { Component } from 'react'

import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { downloadAPI, getAPI } from 'services/jwt'
import store from 'store'
import ReportsTable from 'pages/reports/reportsTable'

import { AutoComplete, Button, Card, DatePicker, Input, Popover, Table } from 'antd'

const { RangePicker } = DatePicker
dayjs.extend(utc)

import { LoadingOutlined } from '@ant-design/icons'
import ShowDateString from 'components/other/dateString'
import { connect } from 'react-redux'
import { ReportsView } from './reportTable/reportView'

const isMobile = window.innerWidth <= 500
class ExpenseReport extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      selectedCategory: { name: '', id: -1 },
      selectedItem: { name: '', id: -1, variant_name: '' },
      autocompleteData: [],
      itemAutoCompleteData: [],
      dates: store.get('reportsDateRange_' + this.props.company_details.company_id)
        ? store.get('reportsDateRange_' + this.props.company_details.company_id)
        : dayjs()
            .startOf('month')
            .format(this.dateFormat) +
          ' - ' +
          dayjs()
            .endOf('month')
            .format(this.dateFormat),
      search: '',
      item_search: '',
      data: [],
      columns: [],
      timeout: null,
      loading: false,
    }
  }

  callData = async (req = {}) => {
    this.setState({ loading: true })
    if (!store.get('paid') && report?.download) {
      this.subscriptionPlanRef.current.openModal('download_excel')
    }
    var report = this.props.report
    var req = {
      page: req.page,
      num_records: req.num_records,
      date: req.date_range,
      download: req.download ?? 0,
      search: req.categoryName ?? '',
      document_type: this.props.report.document_type,
      is_category_group: report.is_category_group,
      company_ids: req.company_ids,
    }

    const data = await downloadAPI('v2/reports', 'expense_report', req)
    if (data.success) {
      this.setState({
        data: data.transactions,
        columns: data.columns,
        total: data.total_records,
        loading: false,
      })
    }
    if (req.download == 0) {
      return {
        [this.props.report.name]: {
          data: data.transactions ?? [],
          // totals: data.total_records ?? 0,
          totals: null,
        },
      }
    }
  }

  download_excel = async download => {
    // if (download == 2 && this.state.columns.length > 15) {
    //   message.error('Too many columns selected. Please select less than 15 columns.')
    //   return
    // }

    if (!store.get('paid')) {
      this.subscriptionPlanRef.current.openModal('download_excel')
    } else {
      var report = this.props.report
      var req = {
        page: this.state.page,
        num_records: this.state.num_records,
        date: this.state.dates,
        download: download,
        search: this.state.selectedCategory.name,
        document_type: this.props.report.document_type,
        is_category_group: report.is_category_group,
      }

      const data = await downloadAPI('v2/reports', 'expense_report', req)
      if (data.success) {
        this.setState({
          data: data.transactions,
          columns: data.columns,
          total: 1,
        })
      }
    }
  }

  async componentDidMount() {
    // await this.callData()
    // await this.categorySearchAPI('')
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.report.is_category_group != this.props.report.is_category_group ||
      prevProps.report.type != this.props.report.type
    ) {
      await this.callData()
    }
  }
  onCategorySelect = (data, option) => {
    this.setState(
      {
        selectedCategory: option,
        autocompleteData: [],
      },
      () => this.callData(),
    )
  }

  categorySearchAPI = async searchText => {
    if (searchText.length >= 0) {
      const req = { query: searchText, type: this.props.report.document_type }
      const data = await getAPI('expenses', 'get_categories', req)
      if (data) {
        var allData = []

        allData = data.categories.map(value => {
          return {
            value: value.category,
            name: value.category,
            id: value.category,
            key: value.category,
          }
        })

        this.setState({
          autocompleteData: [...allData],
        })
      }
    } else {
      this.setState(
        {
          selectedCategory: { name: '', id: -1 },
          autocompleteData: [],
        },
        () => this.callData(0, this.state.num_records),
      )
    }
  }

  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

    return (
      <>
        <ReportsView
          ref={this.reportsViewRef}
          report={{ ...this.props.report, newReport: this.props.newReport }}
          allColumns={{ [this.props.report.name]: this.state.columns }}
          data={{
            data: { [this.props.report.name]: this.state.data },
            total: { [this.props.report.name]: this.state.total },
          }}
          callData={req => this.callData(req)}
          hasCategorySearch={'expenses'}
          hasBrancheSelection={true}
          tooltips={this.state.tooltips}
          type={
            this.props.report.is_category_group
              ? 'category_' + this.props.report.document_type
              : this.props.report.type
          }
          hideComponents={[]}
          setColumns={columns =>
            this.setState({
              columns: columns,
            })
          }
        />

        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
      </>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    company_details: state.permissions.company_details,
    user: state.user,
    country_info: state.countries.info,
  }
}

export default connect(mapStateToProps)(ExpenseReport)
